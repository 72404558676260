<template>
  <Sortable
    class="sortable-list"
    :list="storyPages"
    item-key="id"
    tag="div"
    :options="options"
    @choose="(event) => console.log('X: ', event)"
    @start="cancelLongPress"
    @end="handleStoryPageDragNDrop"
    :style="{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: `${dimenStore.isMobile ? 'flex-start' : 'center'}`,
      height: '100%',
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      gap: '10px',
      paddingInlineStart: '5px',
      paddingInlineEnd: '5px',
      whitespace: 'nowrap',
    }"
    draggable="draggable"
  >
    <template #item="{ element: storyPage, index }">
      <div
        v-if="storyPage.id === 'add-page'"
        :key="'add-page'"
        style="
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
        "
      >
        <v-btn
          :data-testid="`${DataTestIds.storyCreatorView.bottomPages.addPageButton}`"
          size="large"
          class="rounded-btn"
          color="secondary"
          ripple
          flat
          @click="addStoryPage(index)"
          icon="mdi-plus"
          variant="text"
          style="color: black; background-color: transparent; flex-shrink: 0"
        >
        </v-btn>
      </div>
      <div
        v-else
        class="draggable"
        :key="storyPage.id"
        @click="
          () => {
            !deleteStoryPagesToggleMode &&
              props.updateCurrentStoryPageIndex(index);
          }
        "
        :class="`horizontal-scroll-item#${storyPage.id}`"
        :style="{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          flexShrink: '0',
        }"
      >
        <v-btn
          :data-testid="`${DataTestIds.storyCreatorView.bottomPages.storyPageButton}${storyPage.id}`"
          style="height: 100%; width: 100%; padding: 0"
          @mousedown="(event) => handleLongPress(event, index)"
          @touchstart="(event) => handleLongPress(event, index)"
          @mouseup="
            () => {
              !deleteStoryPagesToggleMode &&
                props.updateCurrentStoryPageIndex(index);
              deleteStoryPagesToggleMode && handleLongPress(event, index);
            }
          "
          @mouseleave="cancelLongPress"
          @touchend="cancelLongPress"
          :ref="(el) => (storyPageButtonRefs[index] = el)"
        >
          <div
            :data-testid="`${DataTestIds.storyCreatorView.bottomPages.deletePageButton}${storyPage.id}`"
            :style="{
              position: 'absolute',
              height: '100%',
              width: '100%',
              zIndex: '99',
              backgroundColor: '#adb5bdbf',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              visibility: `${
                deleteStoryPagesToggleMode ? 'visible' : 'hidden'
              }`,
              pointerEvents: `${deleteStoryPagesToggleMode ? 'auto' : 'none'}`,
            }"
            @click.stop="handleRemoveStoryPage(storyPage.id)"
          >
            <v-btn
              flat
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: transparent;
              "
            >
              <v-icon
                icon="mdi-trash-can"
                size="x-large"
                :color="errorColor"
              ></v-icon>
            </v-btn>
          </div>
          <v-img
            v-if="storyPage?.media?.url"
            :data-testid="`story-media-bottom-img#${index}`"
            :src="storyPage.media.url"
            width="100px"
            height="100px"
            @error="mediaLoaded[index] = false"
            @load="mediaLoaded[index] = true"
            style="overflow: hidden"
          />
          <v-sheet
            v-else
            :class="`story-page-${props.getStoryPageBackgroundNumberByStoryPageId(
              reservedStoryPageBackgroundNumbers,
              storyPage.id,
              componentName,
            )}`"
            width="100px"
            height="100px"
            rounded
            style="display: flex; align-items: center; justify-content: center"
          >
            <v-icon icon="mdi-image-area"></v-icon>
          </v-sheet>
        </v-btn>
      </div>
    </template>
  </Sortable>
</template>

<script setup>
import { Sortable } from "sortablejs-vue3";
import { useTheme } from "vuetify";
import {
  defineProps,
  onMounted,
  onBeforeUnmount,
  ref,
  watch,
  computed,
  toRaw,
} from "vue";
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useDimenStore } from "@/stores/dimenStore";
import DataTestIds from "@/constants/dataTestIds";

const componentName = "BottomHorizontalStoryPages";

const dimenStore = useDimenStore();

const props = defineProps({
  isEditMode: Boolean,
  storyPages: { type: Array, required: true },
  currentStoryPageIndex: { type: Number, required: true },
  updateCurrentStoryPageIndex: Function,
  handleStoryPageDragNDrop: Function,
  storyPageBackgroundNumbers: { type: Array, required: true },
  reservedStoryPageBackgroundNumbers: Array,
  removeStoryPageBackgroundNumber: Function,
  getStoryPageBackgroundNumberByStoryPageId: Function,
  addStoryPage: { type: Function, required: true },
  removeStoryPage: { type: Function, required: true },
});

const deleteStoryPagesToggleMode = ref(false);
const storyPageButtonRefs = ref(Array(5).fill(null));
const mediaLoaded = ref(Array(5).fill(null));

const storyPages = computed(() => {
  let temp = JSON.parse(JSON.stringify(props.storyPages));
  if (!props.isEditMode && temp.length < 5) {
    temp.push({ id: "add-page" });
  }

  temp.forEach((item, index) => {
    console.debug(
      componentName,
      "computed",
      `storyPages[${index}]:`,
      toRaw(item),
    );
  });

  return temp;
});

// watch(
//   () => props.storyPages,
//   (newStoryPages) => {
//     console.debug(
//       componentName,
//       "watch",
//       "props.storyPages:",
//       JSON.stringify(newStoryPages),
//     );
//     let temp = [...newStoryPages];
//     if (!props.isEditMode && temp.length < 5) {
//       console.debug(
//         componentName,
//         "watch",
//         "props.storyPages",
//         "Adding add-page",
//       );
//       temp.push({ id: "add-page" });
//     }
//     storyPages.value = temp;
//   },
//   { immediate: false, deep: false },
// );

watch(
  () => [props.storyPages, deleteStoryPagesToggleMode.value],
  ([newStoryPages, newDeleteStoryPagesToggleMode]) => {
    // eslint-disable-next-line
    console.debug(
      componentName,
      "watch",
      "props.storyPages:",
      JSON.stringify(newStoryPages),
    );
    if (newStoryPages.length == 1 && newDeleteStoryPagesToggleMode) {
      deleteStoryPagesToggleMode.value = false;
    }
  },
);

const newStoryStore = useNewStoryStore();

const vTheme = useTheme();
const errorColor = vTheme.current.value.colors.error;

const options = ref({
  horizontal: true,
  delayOnTouchOnly: true,
  delay: 500,
});

let longPressTimeout = null;
// eslint-disable-next-line
const handleLongPress = (event, index) => {
  const functionName = "handleLongPress";
  console.info(componentName, functionName);

  props.storyPages.forEach((storyPage) => {
    console.debug(componentName, functionName, "storyPage:", storyPage.value);
  });

  if (props.isEditMode) {
    console.debug(
      componentName,
      functionName,
      "Cannot delete story pages in edit mode",
    );
    return;
  }

  if (props.storyPages?.length == 1) {
    console.debug(
      componentName,
      functionName,
      "Cannot delete the last story page",
    );
    return;
  } else if (deleteStoryPagesToggleMode.value) {
    console.debug(
      componentName,
      functionName,
      "Delete mode already toggled on",
    );
    return;
  }

  console.debug(
    componentName,
    functionName,
    "Story delete mode can be toggled on",
  );
  longPressTimeout = setTimeout(() => {
    console.debug(componentName, functionName, "Toggling delete mode on");
    deleteStoryPagesToggleMode.value = true;
  }, 1500);
};

const cancelLongPress = () => {
  const functionName = "cancelLongPress";
  console.info(componentName, functionName);

  clearTimeout(longPressTimeout);
  longPressTimeout = null;
};

const handleClickOutside = (event) => {
  const functionName = "handleClickOutside";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "event.target: ", event.target);

  cancelLongPress();

  let isClickOutside = true;
  storyPageButtonRefs.value.forEach((ref) => {
    if (ref?.$el?.contains(event.target)) {
      isClickOutside = false;
      return;
    }
  });

  if (isClickOutside) {
    // Handle the click outside logic here
    console.log(
      componentName,
      functionName,
      "Clicked outside of the specified elements",
    );
    deleteStoryPagesToggleMode.value = false;
  } else {
    console.log(
      componentName,
      functionName,
      "Clicked inside of the specified elements",
    );
  }
};

const handleRemoveStoryPage = (storyPageId) => {
  const functionName = "handleRemoveStoryPage";
  console.info(componentName, functionName);

  console.debug(
    componentName,
    functionName,
    "story pages count:",
    storyPages.value.length,
  );

  if (storyPages.value.length > 1) {
    if (window.location.pathname === "/posts/create/") {
      newStoryStore.deleteStoryPage(storyPageId);
    } else {
      props.removeStoryPage(storyPageId);
    }
    props.removeStoryPageBackgroundNumber(
      props.reservedStoryPageBackgroundNumbers,
      storyPageId,
      componentName,
    );
    const updatedStoryPageIndex =
      props.currentStoryPageIndex > 0 ? props.currentStoryPageIndex - 1 : 0;
    if (props.currentStoryPageIndex == updatedStoryPageIndex) {
      // Update the current story page only index if the current story page is removed
      props.updateCurrentStoryPageIndex(updatedStoryPageIndex);
    }
  }
};

const addStoryPage = (newStoryPageIndex) => {
  const functionName = "addStoryPage";
  console.info(componentName, functionName);

  if (window.location.pathname === "/posts/create/") {
    newStoryStore.addStoryPage();
  } else {
    props.addStoryPage(newStoryPageIndex);
  }
};

// Preserve state across HMR updates
if (module.hot) {
  module.hot.accept();
  module.hot.dispose((data) => {
    data.deleteStoryPagesToggleMode = deleteStoryPagesToggleMode.value;
  });
  if (module.hot.data) {
    deleteStoryPagesToggleMode.value =
      module.hot.data.deleteStoryPagesToggleMode;
  }
}

onMounted(() => {
  document.body.addEventListener("click", handleClickOutside);

  if (process.env.NODE_ENV === "development") {
    window.BottomHorizontalStoryPages = {
      handleRemoveStoryPage: handleRemoveStoryPage,
      deleteStoryPagesToggleMode: deleteStoryPagesToggleMode,
      mediaLoaded: mediaLoaded,
    };
  }
});

onBeforeUnmount(() => {
  document.body.removeEventListener("click", handleClickOutside);
});
</script>

<style
  lang="css"
  scoped
  src="./storyCreatorViewStoryPageBackgrounds.css"
></style>
