export default async function (storyStore, storyPageId) {
  const functionName = "pasteFromClipboard";
  console.info(functionName);

  const story = storyStore.stories.find((story) =>
    story.pages.some((page) => page.id === storyPageId),
  );

  try {
    const link = await navigator.clipboard.readText();
    console.log(functionName, "clipboard:", link);

    if (story) {
      const pageIndex = story.pages.findIndex(
        (page) => page.id === storyPageId,
      );
      if (pageIndex !== -1) {
        story.pages[pageIndex].media.url = link;
      } else {
        console.error(functionName, "pageIndex not found", pageIndex);
      }
    }
  } catch (error) {
    console.error(error);
  }
}
