const functionName = "validateStoryPageText";

export default function (storyPageTextToValidate) {
  console.debug(
    functionName,
    "storyPageTextToValidate:",
    storyPageTextToValidate,
  );
  if (storyPageTextToValidate == undefined) {
    return;
  }
  if (storyPageTextToValidate.length == 0) {
    return "Story text is required";
  }

  if (storyPageTextToValidate.trim().length == 0) {
    return true;
  }

  if (storyPageTextToValidate.length < 20) {
    return "Story text too short";
  }

  return true;
}
