import StoryConstants from "@/constants/story";
import { firestore } from "@/firebase";
import updateStoryDraftOnFirestore from "@/utils/story/updateStoryDraftOnFirestore";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "./authStore";
import { useNewStoryStore } from "./newStoryStore";
import { useSnackBarStore } from "./snackBarStore";

const storeName = "draftStoriesStore";

export const useDraftStoriesStore = defineStore(storeName, {
  id: storeName,
  state: () => ({
    isInitialized: ref(false),
    stories: ref([]),
    firestoreListener: ref(null),
    router: useRouter(),
    authStore: useAuthStore(),
    snackbarStore: useSnackBarStore(),
    deleteDraftDialog: ref({
      open: false,
      storyId: null,
      isProcessing: false,
    }),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.log(storeName, functionName);

      watch(
        () => this.authStore.userId,
        (userId) => {
          if (userId) {
            this.firestoreListener = this.firestoreListenerForMyDraftStories();
          } else {
            if (this.firestoreListener) {
              this.firestoreListener();
              this.firestoreListener = null;
            }
          }

          this.isInitialized = true;
        },
      ),
        { immediate: true };
    },
    firestoreListenerForMyDraftStories() {
      const functionName = "firestoreListenerForMyDraftStories";
      console.info(storeName, functionName);

      const q = query(
        collection(firestore, StoryConstants.firestore.storyDrafts.collection),
        where("creatorId", "==", this.authStore.userId),
      );

      this.firestoreListener = onSnapshot(q, (querySnapshot) => {
        let stories = [];
        console.debug(
          storeName,
          functionName,
          "querySnapshot:",
          querySnapshot.docs.length,
        );
        querySnapshot.forEach((doc) => {
          console.debug(storeName, functionName, doc.id, "==", doc.data());
          let docData = doc.data();
          docData.pages.forEach((page) => {
            page.media.valid = null;
          });
          stories.push({ docId: doc.id, ...docData });
        });

        // Sort stories in descending order (from newest to oldest)
        stories.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.debug(
          storeName,
          functionName,
          "stories length:",
          stories.length,
        );
        for (let i = 0; i < stories.length; i++) {
          console.debug(
            storeName,
            functionName,
            `story#${i}:`,
            JSON.stringify(stories[i]),
          );
        }
        this.stories = stories.slice();
      });
    },
    deleteDraftStory() {
      const functionName = "deleteDraftStory";
      console.info(storeName, functionName);
    },
    async validateGifUrl(url) {
      const functionName = "validateGifUrl";
      console.info(storeName, functionName);

      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          console.debug(storeName, functionName, "valid URL:", url);
          resolve(true);
        };
        img.onerror = () => {
          console.error(storeName, functionName, "invalid URL:", url);
          resolve(false);
        };
        img.src = url;
      });
    },

    async checkGifUrl(storyId, storyPageId) {
      const functionName = "checkGifUrl";
      console.info(storeName, functionName);

      const storyIndex = this.stories.findIndex(
        (story) => story.id === storyId,
      );

      const storyPage = this.stories[storyIndex].pages.find(
        (page) => page.id === storyPageId,
      );

      const isValid = await this.validateGifUrl(storyPage.media.url);
      console.debug(
        storeName,
        functionName,
        "media url:",
        storyPage.media.url,
        "isValid:",
        isValid,
      );

      this.setUrlValidity(storyId, storyPageId, isValid);
    },

    setUrlValidity(storyId, storyPageId, validStatus) {
      const functionName = "setUrlValidity";
      console.info(storeName, functionName);
      console.debug(
        storeName,
        functionName,
        "storyId:",
        storyId,
        "storyPageId:",
        storyPageId,
        "validStatus:",
        validStatus,
      );

      const storyIndex = this.stories.findIndex(
        (story) => story.id === storyId,
      );
      if (storyIndex == -1) {
        console.error(
          storeName,
          functionName,
          "invalid index for story:",
          storyIndex,
        );
        return;
      }

      const storyPageIndex = this.stories[storyIndex].pages.findIndex(
        (storyPage) => storyPage.id === storyPageId,
      );

      if (storyPageIndex == -1) {
        console.error(
          storeName,
          functionName,
          "invalid index for story page:",
          storyPageIndex,
        );
        return;
      }

      this.stories[storyIndex].pages[storyPageIndex].media.valid = validStatus;
      console.debug(storeName, functionName, "validStatus:", validStatus);

      if (!validStatus) {
        this.stories[storyIndex].pages[storyPageIndex].media.url = "";
        this.snackbarStore.displayNotification({
          message: "Unable to load media source",
          color: "error",
          timeout: 2500,
        });
      }
    },
    getPageIndexById(storyId, storyPageId) {
      const functionName = "getPageIndexById";
      console.info(storeName, functionName);

      const story = this.stories.find((story) => story.id === storyId);
      if (!story) {
        console.error(storeName, functionName, "story not found:", storyId);
        return -1;
      }

      const storyPageIndex = story.pages.findIndex(
        (storyPage) => storyPage.id === storyPageId,
      );
      if (storyPageIndex === -1) {
        console.error(
          storeName,
          functionName,
          "story page not found:",
          storyPageId,
        );
      }
      console.debug(storeName, functionName, "storyPageIndex:", storyPageIndex);
      return storyPageIndex;
    },
    async uploadStoryDraftToFirestore(draftStoryId, updatedDraftStoryPages) {
      const functionName = "handleUploadStoryDraftToFirestore";
      console.info(storeName, functionName);

      const success = await updateStoryDraftOnFirestore(
        draftStoryId,
        updatedDraftStoryPages,
      );

      if (success) {
        console.debug(storeName, functionName, "draft updated successfully");

        this.snackbarStore.displayNotification({
          message: "Draft updated successfully",
          color: "success",
        });

        setTimeout(() => {
          this.router.replace("/");
        }, 500);
      } else {
        console.error(storeName, functionName, "failed to update draft");

        this.snackbarStore.displayNotification({
          message: "Failed to update draft",
          color: "error",
        });
      }
    },
    async postStoryDraftAsStory(storyDraftToUploadAsStory) {
      const functionName = "postDraftStoryAsStory";
      console.info(storeName, functionName);

      const authStore = useAuthStore();
      const newStoryStore = useNewStoryStore();

      const storyPagesArr = [];
      storyDraftToUploadAsStory.pages.forEach((page) => {
        if (page.id === "add-page") {
          return;
        }
        console.debug(storeName, functionName, "page:", JSON.stringify(page));
        const pageObj = {
          id: page.id ?? uuidv4(),
          text: page.text,
          mediaUrl: page.media.url,
        };
        storyPagesArr.push(pageObj);
      });

      const newStory = {
        id: storyDraftToUploadAsStory.id ?? uuidv4(),
        createdAt: new Date().toUTCString(),
        creatorId: authStore.userId,
        creatorName: authStore.username,
        sharedStatus: storyDraftToUploadAsStory.sharedStatus,
        sharedTo: [
          ...newStoryStore.getSharedToArray(
            storyDraftToUploadAsStory.sharedStatus,
          ),
        ],
        pages: [...storyPagesArr],
      };

      try {
        const docRef = collection(
          firestore,
          StoryConstants.firestore.stories.collection,
        );
        const doc = await addDoc(docRef, newStory);
        console.log(
          storeName,
          functionName,
          "story uploaded successfully to Firestore with ID:",
          doc.id,
        );
        this.snackbarStore.displayNotification({
          message:
            StoryConstants.storydraftEditorView.notifications.postDraftAsStory
              .success,
          color: "success",
        });
        return true;
      } catch (error) {
        console.error(
          storeName,
          functionName,
          "failed to upload story to Firestore: ",
          error,
        );
        this.snackbarStore.displayNotification({
          message:
            StoryConstants.storydraftEditorView.notifications.postDraftAsStory
              .failure,
          color: "error",
        });
        return false;
      }
    },
    async deleteStoryDraftFromFirestore(
      storyDraftId,
      displayNotification = true,
    ) {
      const functionName = "handleDeleteStoryDraftFromFirestore";
      console.info(storeName, functionName);

      const q = query(
        collection(firestore, StoryConstants.firestore.storyDrafts.collection),
        where("id", "==", storyDraftId),
        limit(1),
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.warn(storeName, functionName, "no matching documents found");
        return false;
      }

      const storyDraftToDeleteRef = querySnapshot.docs[0].ref;

      try {
        await deleteDoc(storyDraftToDeleteRef);
        console.info(
          storeName,
          functionName,
          "story draft deleted successfully",
        );
        if (displayNotification) {
          this.snackbarStore.displayNotification({
            message:
              StoryConstants.storydraftEditorView.notifications.deleteDraft
                .success,
            color: "success",
          });
        }

        setTimeout(() => {
          this.router.replace("/");
        }, 500);
      } catch (error) {
        console.error(storeName, functionName, "error deleting draft:", error);
        if (displayNotification) {
          this.snackbarStore.displayNotification({
            message:
              StoryConstants.storydraftEditorView.notifications.deleteDraft
                .failure,
            color: "error",
          });
        }
      }
    },
    /**
     * Opens the dialog where user can confirm or cancel deletion of a draft
     *
     * @param {boolean} dialogState - If 'true', open dialog; if 'false', close dialog
     * @param {string} [storyId] - Required only if 'dialogState' is true
     */
    updateDeleteDraftDialogState(dialogState, storyId = null) {
      const functionName = "updateDeleteDraftDialogState";
      console.info(storeName, functionName);
      console.debug(
        storeName,
        functionName,
        "dialogState:",
        dialogState,
        "storyId:",
        storyId,
      );

      if (dialogState && storyId == null) {
        console.warn(
          storeName,
          functionName,
          "storyId is required when dialogState is true",
        );
      }

      this.deleteDraftDialog.open = dialogState;
      this.deleteDraftDialog.storyId = storyId;
    },
  },
});
