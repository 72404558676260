<template>
  <v-dialog
    :data-testid="`${DataTestIds.storyDraftEditorView.pendingDraftStoryChangesDialog.dialog}`"
    max-width="500"
    v-model="dialog"
    transition="dialog-fade-transition"
    ><template v-slot:default="{ isActive }">
      <v-card title="Unsaved changes" prepend-icon="mdi-progress-pencil">
        <v-btn
          :data-testid="`${DataTestIds.storyDraftEditorView.pendingDraftStoryChangesDialog.backButton}`"
          variant="plain"
          @click="isActive.value = false"
          style="position: absolute; top: 8px; right: 0px; padding: 0px"
          :disabled="props.isProcessing"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="text-center"
          >You have pending changes to your draft</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :data-testid="`${DataTestIds.storyDraftEditorView.pendingDraftStoryChangesDialog.saveDraftButton}`"
            text="save"
            color="primary"
            variant="tonal"
            @click="
              handleUpdateStoryDraftOnFirestore(
                props.draftStoryId,
                props.draftStoryInProgress,
              )
            "
            :disabled="props.isProcessing"
          >
            <v-progress-circular
              v-if="isUploadingToFirestore"
              indeterminate
              size="20"
              width="3"
            ></v-progress-circular>
            <v-icon v-else>mdi-content-save</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :data-testid="`${DataTestIds.storyDraftEditorView.pendingDraftStoryChangesDialog.discardDraftButton}`"
            text="discard"
            color="error"
            variant="text"
            @click="
              {
                handleDiscardDraftChanges();
                isActive.value = false;
              }
            "
            :disabled="props.isProcessing"
          >
            <v-icon>mdi-close</v-icon></v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions> </v-card
    ></template>
  </v-dialog>
</template>

<script setup>
import { defineProps, onMounted, onUnmounted, computed } from "vue";
import { useRouter } from "vue-router";
import DataTestIds from "@/constants/dataTestIds";
import updateStoryDraftOnFirestore from "@/utils/story/updateStoryDraftOnFirestore";
import { useSnackBarStore } from "@/stores/snackBarStore";

const componentName = "PendingDraftStoryChangesDialog";

const router = useRouter();
const snackbarStore = useSnackBarStore();

const props = defineProps({
  dialog: {
    type: Boolean,
    required: true,
  },
  updateDialogState: {
    type: Function,
    required: true,
  },
  draftStoryId: {
    type: [String, null],
    required: true,
  },
  draftStoryInProgress: {
    type: [Object, null],
    required: true,
  },
  isProcessing: {
    type: Boolean,
    required: true,
  },
  updateIsProcessingDeleteDraft: {
    type: Function,
    required: true,
  },
  updateIsProcessingSaveDraft: {
    type: Function,
    required: true,
  },
  updateIsProcessingPostDraft: {
    type: Function,
    required: true,
  },
});

const dialog = computed({
  get: () => props.dialog,
  set: (value) => props.updateDialogState(value),
});

const handleUpdateStoryDraftOnFirestore = async (
  draftStoryId,
  draftStoryInProgress,
) => {
  const functionName = "handleUpdateStoryDraftOnFirestore";
  console.info(componentName, functionName);

  props.updateIsProcessingSaveDraft(true);

  const success = await updateStoryDraftOnFirestore(
    draftStoryId,
    draftStoryInProgress,
  );

  if (success) {
    snackbarStore.displayNotification({
      message: "Draft saved",
      color: "success",
    });

    setTimeout(() => {
      props.updateIsProcessingSaveDraft(false);
      router.replace("/");
    }, 1000);
  } else {
    snackbarStore.displayNotification({
      message: "Failed to save draft",
      color: "error",
    });

    props.updateIsProcessingSaveDraft(false);
  }
};

const handleDiscardDraftChanges = async () => {
  const functionName = "handleDiscardDraftChanges";
  console.info(componentName, functionName);
  router.replace("/");
};

onMounted(() => {
  console.info(componentName, "mounted");
});

onUnmounted(() => {
  console.info(componentName, "unmounted");
});
</script>
