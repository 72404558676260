function getTimeSince(utcString) {
  console.debug("getTimeSince");
  console.debug("getTimeSince", "utcString: ", utcString);

  const now = new Date();
  console.debug("getTimeSince", "now: ", now);
  const utcDate = new Date(utcString);

  const years = now.getUTCFullYear() - utcDate.getUTCFullYear();
  const months = now.getUTCMonth() - utcDate.getUTCMonth();
  const days = now.getUTCDate() - utcDate.getUTCDate();
  const hours = now.getUTCHours() - utcDate.getUTCHours();
  const minutes = now.getUTCMinutes() - utcDate.getUTCMinutes();
  const seconds = now.getUTCSeconds() - utcDate.getUTCSeconds();

  const result = {
    years: years,
    months: months,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
  console.debug("getTimeSince", "result: ", result);

  let resultString = "";
  if (result.years > 0) {
    resultString = `${result.years} year${result.years > 1 ? "s" : ""} ago`;
  } else if (result.months > 0) {
    resultString = `${result.months} month${result.months > 1 ? "s" : ""} ago`;
  } else if (result.days > 0) {
    resultString = `${result.days} day${result.days > 1 ? "s" : ""} ago`;
  } else if (result.hours > 0) {
    resultString = `${result.hours} hour${result.hours > 1 ? "s" : ""} ago`;
  } else if (result.minutes > 0) {
    resultString = `${result.minutes} minute${
      result.minutes > 1 ? "s" : ""
    } ago`;
  } else if (result.seconds >= 0) {
    resultString = `${result.seconds} second${
      result.seconds > 1 ? "s" : ""
    } ago`;
  }

  return resultString;
}

export default getTimeSince;
