<template>
  <v-dialog
    :data-testid="`${DataTestIds.storyCreatorView.pendingStoryChangesDialog.dialog}`"
    max-width="500"
    v-model="dialogStore.discardChangesDialog"
    transition="dialog-fade-transition"
    ><template v-slot:default="{ isActive }">
      <v-card title="Unsaved changes" prepend-icon="mdi-progress-pencil">
        <v-btn
          :data-testid="`${DataTestIds.storyCreatorView.pendingStoryChangesDialog.backButton}`"
          variant="plain"
          @click="isActive.value = false"
          style="position: absolute; top: 8px; right: 0px; padding: 0px"
          :disabled="isProcessing"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="text-center"
          >Save the story as a draft or discard it?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :data-testid="`${DataTestIds.storyCreatorView.pendingStoryChangesDialog.saveDraftButton}`"
            color="primary"
            variant="tonal"
            @click="handleUploadStoryDraftToFirestore(isActive)"
            :disabled="isProcessing"
          >
            <v-progress-circular
              v-if="isUploadingToFirestore"
              indeterminate
              size="20"
              width="3"
            ></v-progress-circular>
            <v-icon v-else>mdi-content-save</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :data-testid="`${DataTestIds.storyCreatorView.pendingStoryChangesDialog.discardDraftButton}`"
            color="error"
            variant="tonal"
            @click="handleDeletingDraftFromLocalStorage(isActive)"
            :disabled="isProcessing"
          >
            <v-progress-circular
              v-if="isDeletingDraft"
              indeterminate
              size="20"
              width="3"
            ></v-progress-circular>
            <v-icon v-else>mdi-delete</v-icon></v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions> </v-card
    ></template>
  </v-dialog>
</template>

<script setup>
import { useDialogStore } from "@/stores/dialogStore";
import { useRouter } from "vue-router";
import { useSnackBarStore } from "@/stores/snackBarStore";
import uploadStoryDraftToFirestore from "@/utils/story/uploadStoryDraftToFirestore";
import deleteDraftStoryFromLocalStorage from "@/utils/story/deleteDraftStoryFromLocalStorage";
import DataTestIds from "@/constants/dataTestIds";
import { computed, onMounted, ref, defineProps } from "vue";

const componentName = "PendingStoryChangesDialog";

const props = defineProps({
  cancelDraftDelay: {
    type: Function,
    required: true,
  },
});

const router = useRouter();
const dialogStore = useDialogStore();
const snackbarStore = useSnackBarStore();

const isUploadingToFirestore = ref(false);
const isDeletingDraft = ref(false);

const isProcessing = computed(() => {
  return isUploadingToFirestore.value || isDeletingDraft.value;
});

const handleUploadStoryDraftToFirestore = async (isActive) => {
  const functionName = "handleUploadStoryDraftToFirestore";
  console.info(componentName, functionName);

  isUploadingToFirestore.value = true;

  props.cancelDraftDelay();

  const success = await uploadStoryDraftToFirestore();
  if (success) {
    deleteDraftStoryFromLocalStorage();
    snackbarStore.displayNotification({
      message: "Draft saved successfully",
      color: "success",
    });
    isActive.value = false;
    router.replace("/");
  } else {
    snackbarStore.displayNotification({
      message: "Failed to save draft",
      color: "error",
    });
  }
  isUploadingToFirestore.value = false;
};

const handleDeletingDraftFromLocalStorage = (isActive) => {
  const functionName = "handleDeletingDraftFromLocalStorage";
  console.info(componentName, functionName);

  props.cancelDraftDelay();

  isDeletingDraft.value = true;
  deleteDraftStoryFromLocalStorage();
  isActive.value = false;
  isDeletingDraft.value = false;
  router.replace("/");
};

onMounted(() => {
  const functionName = "onMounted";
  console.info(componentName, functionName);

  window.pendingStoryChangesDialog = {
    isProcessing: isProcessing,
    isUploadingToFirestore: isUploadingToFirestore,
    isDeletingDraft: isDeletingDraft,
  };
});
</script>
