/**
 *
 * This function requires parent component to have a Vue ref for storing reserved story page background numbers.
 *
 * @param {*} reservedStoryPageBackgroundNumbers
 * @param {*} storyPageId
 * @returns number that is used to pick a background image for a story page placeholder
 */

export function getStoryPageBackgroundNumberByStoryPageId(
  reservedStoryPageBackgroundNumbers,
  storyPageId,
  componentName = "",
) {
  const functionName = "getStoryPageBackgroundNumberByStoryPageId";
  console.info(componentName, functionName);

  const reservedNumber = reservedStoryPageBackgroundNumbers.find(
    (item) => item.storyPageId === storyPageId,
  )?.number;
  if (reservedNumber !== undefined) {
    console.debug(
      componentName,
      functionName,
      "Reserved number:",
      reservedNumber,
    );
    return reservedNumber;
  } else {
    console.debug(
      componentName,
      functionName,
      "No reserved number found for storyPageId:",
      storyPageId,
    );
    return null;
  }
}

/**
 *
 * @param {*} storyPageBackgroundNumbers array of numbers for story pages
 * @param {*} reservedStoryPageBackgroundNumbers array of numbers that are reserved for story pages
 * @param {*} storyPageId id for the story page
 * @returns first available number that is not reserved
 */
export function getStoryPageBackgroundNumber(
  storyPageBackgroundNumbers,
  reservedStoryPageBackgroundNumbers,
  storyPageId,
  componentName = "",
) {
  const functionName = "getStoryPageBackgroundNumber";
  console.info(componentName, functionName);

  let availableNumber = null;
  for (let number of storyPageBackgroundNumbers) {
    if (
      !reservedStoryPageBackgroundNumbers.value.some(
        (item) => item.number === number,
      )
    ) {
      console.debug(componentName, functionName, "Available number:", number);
      // Keep track of the reserved number
      reservedStoryPageBackgroundNumbers.value.push({
        storyPageId: storyPageId,
        number: number,
      });

      // Return the available number
      availableNumber = number;
      break;
    } else {
      console.debug(
        componentName,
        functionName,
        "Number already reserved:",
        number,
      );
    }
  }

  return availableNumber;
}

/**
 *
 * @param {*} reservedStoryPageBackgroundNumbers
 * @param {*} storyPageId
 */

export function removeStoryPageBackgroundNumber(
  reservedStoryPageBackgroundNumbers,
  storyPageId,
  componentName = "",
) {
  const functionName = "removeStoryPageBackgroundNumber";
  console.info(componentName, functionName);

  const reservedNumberIndex = reservedStoryPageBackgroundNumbers.findIndex(
    (item) => item.storyPageId === storyPageId,
  );
  console.debug(
    componentName,
    functionName,
    "reservedNumberIndex:",
    reservedNumberIndex,
  );
  if (reservedNumberIndex !== -1) {
    const reservedNumber = reservedStoryPageBackgroundNumbers.splice(
      reservedNumberIndex,
      1,
    )[0];
    console.debug(
      componentName,
      functionName,
      "Removed reserved number:",
      reservedNumber,
    );
  } else {
    console.debug(
      componentName,
      functionName,
      "No reserved number found for storyPageId:",
      storyPageId,
    );
  }
}
