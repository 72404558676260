export default function (isStartTimestamp = false) {
  const functionName = "saveDraftDelayTimestampInTesting";
  const draftDelayTimestamp = new Date().getTime();
  console.debug(
    functionName,
    "Setting new draft save delay at:",
    draftDelayTimestamp,
  );
  if (isStartTimestamp) {
    if (window.StoryCreatorView === undefined) {
      window.StoryCreatorView = {};
    }
    window.StoryCreatorView.saveDraftDelayStartTimestamp = draftDelayTimestamp;
  } else {
    if (window.StoryCreatorView === undefined) {
      window.StoryCreatorView = {};
    }
    window.StoryCreatorView.saveDraftDelayFinishTimestamp = draftDelayTimestamp;
  }
}
