const StoryConstant = require("@/constants/story");
const { useNewStoryStore } = require("@/stores/newStoryStore");
const functionName = "saveDraftToLocalStorage";

/**
 *
 * @param {*} latestStory Current story-object in newStoryStore which is either just created
 * in-memory or loaded from localStorage
 * @returns {boolean} True if draft was saved successfully, false otherwise
 */

export default async function (updateIsSavingDraft, updateTitle) {
  console.info(functionName);

  let startTime = Date.now();

  updateIsSavingDraft(true);
  updateTitle(StoryConstant.appBarTitle.saveDraft.inProgress);

  const newStoryStore = useNewStoryStore();
  const draftStory = JSON.parse(JSON.stringify(newStoryStore.getStoryObject())); // Copy by value
  draftStory.pages.forEach((storyPage) => {
    delete storyPage.number; // Will be calculated again on load
    delete storyPage.media.valid; // Will be validated again on load
  });
  draftStory.draftSavedAt = new Date().toUTCString();

  console.debug(
    functionName,
    "Saving draft story to localStorage:",
    JSON.stringify(draftStory),
  );

  let elapsedTime, remainingTime;

  try {
    localStorage.setItem(
      StoryConstant.localStorage.storyDraft,
      JSON.stringify(draftStory),
    );
    console.debug(functionName, "Draft saved successfully");

    // Wait for a minimum duration before changing the app bar title to make it more easily readable
    elapsedTime = Date.now() - startTime;
    remainingTime = StoryConstant.appBarTitle.minDurationMs - elapsedTime;
    if (remainingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }

    updateTitle(StoryConstant.appBarTitle.saveDraft.success);
    return true;
  } catch (error) {
    console.error(functionName, "Failed to save draft:", error);

    // Wait for a minimum duration before changing the app bar title to make it more easily readable
    elapsedTime = Date.now() - startTime;
    remainingTime = StoryConstant.appBarTitle.minDurationMs - elapsedTime;
    if (remainingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }

    updateTitle(StoryConstant.appBarTitle.saveDraft.failure);
    return false;
  } finally {
    setTimeout(() => {
      updateIsSavingDraft(false);
      updateTitle(StoryConstant.appBarTitle.default);
      return true;
    }, StoryConstant.appBarTitle.minDurationMs);
  }
}
