export function handleFocus(componentName) {
  const functionName = "handleFocus";
  console.debug(componentName, functionName, "inputElement focus");
  document.body.style.position = "";
}

export function handleBlur(componentName) {
  const functionName = "handleBlur";
  console.debug(componentName, functionName, "inputElement blur");
  document.body.style.position = "fixed";
}
