import Storyconstant from "@/constants/story";
import { firestore } from "@/firebase";
import {
  collection,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const functionName = "updateStoryDraftOnFirestore";

export default async function (draftStoryId, updatedStoryDraft) {
  console.info(functionName);

  console.debug(
    functionName,
    "draftStoryId:",
    draftStoryId,
    "updatedStoryDraft:",
    JSON.stringify(updatedStoryDraft),
  );

  try {
    const q = query(
      collection(firestore, Storyconstant.firestore.storyDrafts.collection),
      where("id", "==", draftStoryId),
      limit(1),
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log(functionName, "no matching document found.");
      return false;
    }

    const docRef = querySnapshot.docs[0].ref;

    updatedStoryDraft.updatedAt = new Date().toUTCString();

    console.debug(
      functionName,
      "updatedStoryDraft after updating updatedAt-timestamp:",
      JSON.stringify(updatedStoryDraft),
    );

    await updateDoc(docRef, updatedStoryDraft);

    console.debug(functionName, "draft updated successfully");
    return true;
  } catch (error) {
    console.error(functionName, `error: ${error}`);
    return false;
  }
}
