<template>
  <v-dialog
    :data-testid="`${DataTestIds.storyDraftEditorView.deleteDraftDialog.dialog}`"
    max-width="500"
    v-model="draftStoriesStore.deleteDraftDialog.open"
    transition="dialog-fade-transition"
    :persistent="draftStoriesStore.deleteDraftDialog.isProcessing"
  >
    <v-card title="Delete story draft">
      <v-card-text>
        <p style="display: inline">
          Are you sure you want to delete this story draft?
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :data-testid="`${DataTestIds.common.deleteDraftDialog.cancelButton}`"
          :disabled="draftStoriesStore.deleteDraftDialog.isProcessing"
          text="Cancel"
          variant="text"
          color="primary"
          @click="
            () => {
              draftStoriesStore.updateDeleteDraftDialogState(false);
            }
          "
          ><v-icon icon="mdi-close"></v-icon
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :data-testid="`${DataTestIds.common.deleteDraftDialog.confirmButton}`"
          :disabled="draftStoriesStore.deleteDraftDialog.isProcessing"
          text="Delete"
          color="error"
          variant="tonal"
          @click="
            async () => {
              draftStoriesStore.deleteDraftDialog.isProcessing = true;
              await draftStoriesStore.deleteStoryDraftFromFirestore(
                draftStoriesStore.deleteDraftDialog.storyId,
              );
              draftStoriesStore.updateDeleteDraftDialogState(false);
              draftStoriesStore.deleteDraftDialog.isProcessing = false;
            }
          "
          ><v-icon icon="mdi-trash-can"></v-icon
        ></v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import DataTestIds from "@/constants/dataTestIds";
import { useDraftStoriesStore } from "@/stores/draftStoriesStore";
import { onMounted, onUnmounted } from "vue";

const componentName = "DeleteDraftDialog";

const draftStoriesStore = useDraftStoriesStore();

onMounted(() => {
  console.info(componentName, "mounted");
});

onUnmounted(() => {
  console.info(componentName, "unmounted");
});
</script>
