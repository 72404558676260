const functionName = "isUpdatedStoryPages";

export default function (originalStoryPages, updatedStoryPages) {
  console.info(functionName);

  console.debug(
    functionName,
    "originalStory:",
    JSON.stringify(originalStoryPages),
    "updatedStory:",
    JSON.stringify(updatedStoryPages),
  );

  if (originalStoryPages === undefined || updatedStoryPages === undefined) {
    console.error(
      functionName,
      "stories are undefined, they may not be loaded yet",
    );
    return false;
  }

  if (originalStoryPages.length !== updatedStoryPages.length) {
    console.error(functionName, "stories have different number of pages");
    return true;
  }

  for (let i = 0; i < originalStoryPages.length; i++) {
    const originalPage = originalStoryPages[i];
    const updatedPage = updatedStoryPages[i];

    if (originalPage.text !== updatedPage.text) {
      console.error(functionName, `Page #${i} text differs`);
      return true;
    }

    if (originalPage.media?.url !== updatedPage.media?.url) {
      console.error(functionName, `Page #${i} media URL differs`);
      return true;
    }
  }

  console.debug(functionName, "stories are the same");
  return false;
}
