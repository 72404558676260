<template>
  <v-app-bar-nav-icon
    v-if="!props.isEditMode"
    :data-testid="DataTestIds.storyCreatorView.appBar.resetStoryButton"
    :disabled="!isStoryResetable"
    color="secondary"
    @click="handleResetStory"
  >
    <v-icon>mdi-book-refresh</v-icon>
  </v-app-bar-nav-icon>
</template>

<script setup>
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { defineProps, computed, onMounted, onUnmounted } from "vue";
import DataTestIds from "@/constants/dataTestIds";
import StoryConstants from "@/constants/story";
import deleteDraftFromLocalStorage from "@/utils/story/deleteDraftStoryFromLocalStorage";

const componentName = "ResetStoryButton";

const newStoryStore = useNewStoryStore();
const snackbarStore = useSnackBarStore();

const props = defineProps({
  isEditMode: Boolean,
});

const handleResetStory = () => {
  const functionName = "handleResetStory";
  console.info(componentName, functionName);
  newStoryStore.resetStory();
  const success = deleteDraftFromLocalStorage();
  if (success) {
    console.debug(
      componentName,
      functionName,
      "Draft story deleted from local storage",
    );
    snackbarStore.displayNotification({
      message: StoryConstants.storyCreatorView.notifications.resetStory.success,
      color: "success",
    });
  } else {
    console.debug(componentName, functionName, "Draft story deletion failed");
    snackbarStore.displayNotification({
      message: StoryConstants.storyCreatorView.notifications.resetStory.failure,
      color: "error",
    });
  }
};

const isStoryResetable = computed(() => {
  return newStoryStore.pages.every(
    (page) => page.media.url.length !== "" && page.text !== "",
  );
});

onMounted(() => {
  console.info(componentName, "mounted");

  if (window.automatedTesting) {
    if (window.ResetStoryButton === undefined) {
      window.ResetStoryButton = {};
    }

    window.ResetStoryButton = {
      isStoryResetable: isStoryResetable,
    };
  }
});

onUnmounted(() => {
  console.info(componentName, "unmounted");
});
</script>
