import saveDraftStoryToLocalStorage from "./saveDraftStoryToLocalStorage";
import deleteDraftStoryFromLocalStorage from "./deleteDraftStoryFromLocalStorage";
import StoryConstants from "@/constants/story";
import StoryDraftLoadingStatus from "@/constants/StoryDraftLoadingStatus";

const isStoryReset = (
  storyPagesInProgressLatest,
  storyPagesInProgressPrevious,
) => {
  const functionName = "isStoryReset";
  console.info(functionName);

  const isStoryPagesInProgressLatestEmpty = storyPagesInProgressLatest.every(
    (storyPage) =>
      storyPage.media.url.trim() === "" && storyPage.text.trim() === "",
  );
  console.debug(
    functionName,
    "isStoryPagesInProgressLatestEmpty:",
    isStoryPagesInProgressLatestEmpty,
  );

  const isStoryPagesInProgressPreviousEmpty = storyPagesInProgressPrevious
    ? storyPagesInProgressPrevious.every(
        (storyPage) =>
          storyPage.media.url.trim() === "" && storyPage.text.trim() === "",
      )
    : true;

  console.debug(
    functionName,
    "isStoryPagesInProgressPreviousEmpty:",
    isStoryPagesInProgressPreviousEmpty,
  );

  if (
    isStoryPagesInProgressLatestEmpty &&
    !isStoryPagesInProgressPreviousEmpty
  ) {
    console.debug(
      functionName,
      "Story has been emptied; Delete draft if exists",
    );
    return true;
  } else {
    console.debug(functionName, "Story has not been emptied");
    return false;
  }
};

const isUrlsValidated = (storyPagesInProgressLatest) => {
  const functionName = "isUrlsValidated";
  console.info(functionName);

  let urlsValidated = true;
  for (const storyPage of storyPagesInProgressLatest) {
    if (storyPage.media.url.trim().length > 0 && !storyPage.media.valid) {
      urlsValidated = false;
      console.debug(functionName, "Non-empty URLs not validated");
      break;
    }
  }
  console.debug(functionName, "urlsValidated:", urlsValidated);
  return urlsValidated;
};

/**
 * This function handles saving the story-in-progress as a draft to local storage.
 * In case the story-in-progress is emptied/reset, the draft is deleted (in case it has been stored in local storage).
 *
 * @param {import('vue').Ref<Object>} lastSavedStoryObj last saved changes for story object (sharedStatus & story pages)
 * @param {import('vue').Ref<Object>} currentStoryObj current in-progress story object (sharedStatus & story pages)
 * @param {import('vue').Ref<Number>} saveDraftDelay Delay for saving draft
 * @param {import('vue').Ref<Boolean>} isSavingDraft State of draft saving
 * @param {import('vue').Ref<Boolean>} isDraftable State of story's draft-ability
 * @param {import('vue').Ref<Boolean>} isLoadingDraftStory State of draft story's loading
 * @param {import('vue').Ref<Boolean>} hasBeenSavedSinceLastChange
 * @param {Function} updateHasBeenSavedSinceLastChange
 * @param {Function} updateIsSavingDraft Function to update draft saving state
 * @param {Function} updateTitle Function to update the app bar's title
 * @returns {void}
 */

// TODO: May be null when trying to read pages

export default async function (
  lastSavedStoryObj,
  currentStoryObj,
  saveDraftDelay,
  isSavingDraft,
  isDraftable,
  isLoadingDraftStory,
  hasBeenSavedSinceLastChange,
  updateHasBeenSavedSinceLastChange,
  updateIsSavingDraft,
  updateTitle,
) {
  const functionName = "handleSaveStoryToLocalStorage";
  console.info(functionName);

  console.debug(
    functionName,
    "lastSavedStoryObj:",
    JSON.stringify(lastSavedStoryObj.value),
    "currentStoryObj:",
    JSON.stringify(currentStoryObj.value),
    "saveDraftDelay:",
    saveDraftDelay.value,
    "isSavingDraft:",
    isSavingDraft.value,
    "isDraftable:",
    isDraftable.value,
    "isLoadingDraftStory:",
    isLoadingDraftStory.value,
  );

  let saveDraft = false;

  if (isLoadingDraftStory.value === StoryDraftLoadingStatus.inProgress) {
    console.debug(
      functionName,
      "Loading draft story in progress; Skip checking story's content",
    );
    return;
  }

  if (saveDraftDelay.value) {
    console.debug(functionName, "Clearing previous draft save delay");
    clearTimeout(saveDraftDelay.value);
    saveDraftDelay.value = null;
  }

  if (lastSavedStoryObj.value) {
    if (
      lastSavedStoryObj.value.sharedStatus !==
      currentStoryObj.value.sharedStatus
    ) {
      console.debug(functionName, "Shared status has changed; Save draft");
      saveDraft = true;
    } else {
      console.debug(functionName, "Shared status has not changed");
      saveDraft = false;
    }

    let storyPagesDiffer = false;
    for (let i = 0; i < currentStoryObj.value.pages.length; i++) {
      if (
        currentStoryObj.value.pages[i].media.url !==
          lastSavedStoryObj.value.pages[i].media.url ||
        currentStoryObj.value.pages[i].text !==
          lastSavedStoryObj.value.pages[i].text
      ) {
        storyPagesDiffer = true;
        break;
      }
    }

    if (!storyPagesDiffer) {
      console.debug(functionName, "Story pages have not changed");
      saveDraft = false;
    } else {
      console.debug(functionName, "Story pages have changed; Save draft");
      saveDraft = true;
    }
  }

  if (
    isStoryReset(currentStoryObj.value.pages, lastSavedStoryObj.value.pages)
  ) {
    updateTitle(StoryConstants.appBarTitle.deleteDraft.inProgress);
    const success = deleteDraftStoryFromLocalStorage();
    updateTitle(
      success
        ? StoryConstants.appBarTitle.deleteDraft.success
        : StoryConstants.appBarTitle.deleteDraft.failure,
    );
    return;
  }

  if (
    currentStoryObj.value.pages.length === 0 &&
    lastSavedStoryObj.value.pages.length !== 0
  ) {
    console.debug(
      functionName,
      "Story has been emptied; Delete draft if exists",
    );
    const success = deleteDraftStoryFromLocalStorage();
    updateTitle(
      success
        ? StoryConstants.appBarTitle.deleteDraft.success
        : StoryConstants.appBarTitle.deleteDraft.failure,
    );
    return;
  }

  if (!isUrlsValidated(currentStoryObj.value.pages)) {
    console.debug(functionName, "URLs not validated; Don't save draft");
    saveDraft = false;
  }

  if (saveDraft) {
    console.debug(functionName, "Story has changed");
    isDraftable.value = true;
  } else {
    console.debug(functionName, "Story has not changed");
    isDraftable.value = false;
  }

  if (!isDraftable.value) {
    console.debug(functionName, "Story is not draftable; Skip saving draft");
    return;
  }

  const success = saveDraftStoryToLocalStorage(
    updateIsSavingDraft,
    updateTitle,
  );
  if (success) {
    lastSavedStoryObj.value = {
      sharedStatus: currentStoryObj.value.sharedStatus,
      pages: JSON.parse(JSON.stringify(currentStoryObj.value.pages)),
    };
    console.debug(
      functionName,
      "lastSavedStoryObj updated:",
      JSON.stringify(lastSavedStoryObj.value),
    );
    if (!hasBeenSavedSinceLastChange) {
      updateHasBeenSavedSinceLastChange(true);
    }
  } else {
    console.warn(functionName, "Failed to save draft");
  }
}
