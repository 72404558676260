import { firestore } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";
import StoryConstant from "@/constants/story";
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useAuthStore } from "@/stores/authStore";

const functionName = "uploadStoryDraftToFirestore";

export default async function () {
  console.info(functionName);

  const authStore = useAuthStore();

  const newStoryStore = useNewStoryStore();

  let storyToSaveAsDraft = JSON.parse(
    JSON.stringify(newStoryStore.getStoryObject()),
  );

  storyToSaveAsDraft.pages.forEach((storyPage) => {
    delete storyPage.number;
    delete storyPage.media.valid;
  });

  storyToSaveAsDraft.draftSavedAt = new Date().toUTCString();
  storyToSaveAsDraft.createdAt = new Date().toUTCString();
  storyToSaveAsDraft.creatorId = authStore.userId;
  storyToSaveAsDraft.creatorName = authStore.username;
  storyToSaveAsDraft.sharedTo = newStoryStore.getSharedToArray(
    authStore.sharedStatus,
  );
  storyToSaveAsDraft.id = newStoryStore.id;

  console.debug(
    functionName,
    "story to save as draft:",
    JSON.stringify(storyToSaveAsDraft),
  );

  try {
    const docRef = collection(
      firestore,
      StoryConstant.firestore.storyDrafts.collection,
    );
    const doc = await addDoc(docRef, storyToSaveAsDraft);
    console.log(
      functionName,
      "draft saved successfully to Firestore with ID:",
      doc.id,
    );
    return true;
  } catch (error) {
    console.error(functionName, "failed to save draft to Firestore: ", error);
    return false;
  }
}
