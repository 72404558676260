module.exports = Object.freeze({
  draftSaveDelay: 2500,
  appBarTitle: {
    minDurationMs: 3000,
    default: "Storyque",
    saveDraft: {
      inProgress: "Saving Draft...",
      success: "Draft Saved",
      failure: "Failed to Save Draft",
    },
    deleteDraft: {
      inProgress: "Deleting Draft...",
      success: "Draft Deleted",
      failure: "Failed to Delete Draft",
    },
  },
  localStorage: {
    storyDraft: "storyDraft",
  },
  firestore: {
    stories: {
      collection: "stories",
    },
    storyDrafts: {
      collection: "storyDrafts",
    },
  },
  notifications: {
    storyDraft: {
      load: {
        success: "Draft story loaded",
        failure: "Error occurred when loading draft story",
      },
      upload: {
        success: "Story draft uploaded",
        failure: "Error occurred when uploading story draft",
      },
    },
  },
  storyCreatorView: {
    notifications: {
      resetStory: {
        success: "Story reset successfully",
        failure: "Error occurred when resetting story",
      },
    },
  },
  storydraftEditorView: {
    notifications: {
      postDraftAsStory: {
        success: "Story draft posted successfully",
        failure: "Error occurred when posting story draft",
      },
      deleteDraft: {
        success: "Draft deleted successfully",
        failure: "Error occurred when deleting draft",
      },
    },
  },
});
