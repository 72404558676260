const StoryConstant = require("@/constants/story");
const functionName = "deleteDraftStoryFromLocalStorage";

export default function () {
  console.info(functionName);

  try {
    localStorage.removeItem(StoryConstant.localStorage.storyDraft);
    console.debug(functionName, "Draft deleted successfully");
    return true;
  } catch (error) {
    console.error(functionName, "Failed to delete draft:", error);
    return false;
  }
}
