import StoryDraftEditor from "@/components/storyDraftEditor/StoryDraftEditor.vue";
import { watch } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../stores/authStore";
import AuthView from "../views/AuthView";
import FriendRequestVerifyView from "../views/FriendRequestVerifyView";
import LinkDevicesCreateView from "../views/LinkDevicesCreateView";
import LinkDevicesVerifyView from "../views/LinkDevicesVerifyView";
import StoriesSharedWithYou from "../views/StoriesSharedWithYou";
import StoryCreatorView from "../views/StoryCreatorView";
import StoryViewer from "../views/StoryViewer";

const componentName = "routes";

const routes = [
  { path: "/", redirect: "/profile" },
  { path: "/home", redirect: "/profile" },
  {
    path: "/auth",
    name: "auth",
    component: AuthView,
    meta: { requiresAuth: false, displayTopNav: true },
  },
  {
    path: "/profile",
    name: "profile",
    meta: { requiresAuth: true, displayBotNav: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfileView.vue"),
  },
  {
    path: "/posts/:postId/:isDraft?",
    name: "posts",
    component: StoryViewer,
    meta: { requiresAuth: false, displayTopNav: true, displayBotNav: false },
  },
  {
    path: "/posts/create/:sharedStatus?",
    name: "storyCreator",
    component: StoryCreatorView,
    meta: { requiresAuth: true, displayTopNav: true },
  },
  {
    path: "/posts/edit/:postId",
    name: "storyEditor",
    component: StoryCreatorView,
    meta: { requiresAuth: true, displayTopNav: true },
  },
  {
    path: "/posts/drafts/:draftId",
    name: "draftEditor",
    component: StoryDraftEditor,
    meta: { requiresAuth: true, displayTopNav: true },
  },
  {
    path: "/linkDevices/create",
    name: "linkDevicesCreate",
    component: LinkDevicesCreateView,
    meta: { requiresAuth: true },
  },
  {
    path: "/linkDevices/verify/:code",
    name: "linkDevicesVerify",
    component: LinkDevicesVerifyView,
    meta: { requiresAuth: false, requiresGuest: true },
  },
  {
    path: "/friendRequests/:code",
    name: "friendRequestVerify",
    component: FriendRequestVerifyView,
    meta: { requiresAuth: true, displayTopNav: true },
  },
  {
    path: "/privateSharedPosts",
    name: "privateSharedPosts",
    component: StoriesSharedWithYou,
    meta: { requiresAuth: true, displayBotNav: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let previousRoute = null;

router.beforeEach(async (to, from, next) => {
  previousRoute = from;
  const authStore = useAuthStore();

  // Wait for authStore to be initialized
  if (!authStore.initialized) {
    await new Promise((resolve) => {
      const unwatch = watch(
        () => authStore.initialized,
        (value) => {
          if (value) {
            unwatch();
            resolve();
          }
        },
      );
    });
  }

  console.debug(componentName, "to: ", to, "from: ", from);

  console.debug(
    componentName,
    "authStore.initialized: ",
    authStore.initialized,
    "authStore.user: ",
    JSON.stringify(authStore.user),
    "authStore.username: ",
    authStore.username,
    "authStore.userId: ",
    authStore.userId,
  );

  if (!authStore.initialized) {
    // If the store is not initialized, wait for it to become initialized
    await new Promise((resolve) => {
      const unwatch = watch(
        () => authStore.initialized,
        (value) => {
          console.debug(componentName, "authStore.initialized: ", value);

          if (value) {
            unwatch(); // Stop watching once the store is initialized
            resolve();
          }
        },
      );
    });
  }

  if (to.meta.requiresGuest && authStore.user) {
    next({ name: "profile" }); // Temporary solution to prevent user from accidentally linking their already linked device
  }

  if (to.meta.requiresAuth && !authStore.user) {
    console.debug(componentName, "Authentication required for path: ", to.name);
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (to.name !== "auth") {
      next({ name: "auth", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export function getPreviousRoute() {
  return previousRoute;
}

export default router;
