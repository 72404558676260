<template>
  <v-app-bar app :elevation="2" rounded density="compact">
    <template v-slot:prepend>
      <v-app-bar-nav-icon
        @click="goBack"
        :data-testid="`${DataTestIds.storyDraftEditorView.appBar.backButton}`"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title
        :style="{
          fontWeight:
            title === StoryConstant.appBarTitle.default ? 'bold' : 'lighter',
        }"
        :data-testid="`${DataTestIds.storyCreatorView.appBarTitle}`"
        >{{ title }}</v-app-bar-title
      >
    </template>
    <template v-slot:append>
      <v-skeleton-loader
        v-if="props.draftStoryInProgress?.sharedStatus === undefined"
        type="button"
      ></v-skeleton-loader>
      <StoryShareStatusDial
        v-else
        :storyStore="draftStoriesStore"
        :sharedStatus="props.draftStoryInProgress.sharedStatus"
        :storyId="props.draftStoryInProgress.id"
      />
      <v-app-bar-nav-icon
        :data-testid="`${DataTestIds.storyDraftEditorView.appBar.deleteDraftButton}`"
        color="error"
        :disabled="isProcessing"
        @click="handleDeleteStoryDraft(props.draftStoryId)"
      >
        <v-icon v-if="!isProcessing">mdi-trash-can</v-icon>
        <v-progress-circular v-else color="error" indeterminate />
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        :data-testid="`${DataTestIds.storyDraftEditorView.appBar.saveDraftButton}`"
        color="secondary"
        :disabled="!isStoryDraftUpdateable || isProcessing"
        @click="handleUpdateStoryDraftOnFirestore(props.draftStoryId)"
      >
        <v-icon v-if="!isProcessingUpdateStoryDraftOnFirestore"
          >mdi-content-save</v-icon
        >
        <v-progress-circular v-else color="secondary" indeterminate />
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        :data-testid="`${DataTestIds.storyDraftEditorView.appBar.postDraftButton}`"
        color="primary"
        :disabled="!isStoryPostable || isProcessing"
        @click="handlePostStoryDraft"
      >
        <v-icon v-if="!isProcessing">mdi-send-variant</v-icon>
        <v-progress-circular v-else color="primary" indeterminate />
      </v-app-bar-nav-icon>
    </template>
  </v-app-bar>
</template>

<script setup>
import StoryShareStatusDial from "@/components/storyCreator/StoryShareStatusDial.vue";
import DataTestIds from "@/constants/dataTestIds";
import StoryConstant from "@/constants/story";
import { useDraftStoriesStore } from "@/stores/draftStoriesStore";
import { handleRouting } from "@/utils/routing/handleRouting";
import isStoryPostableFunc from "@/utils/story/isStoryPostable";
import isUpdatedStoryPages from "@/utils/story/isUpdatedStoryPages";
import { computed, defineProps, ref, toRaw, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

const componentName = "StoryDraftEditorAppBar";

const router = useRouter();
const draftStoriesStore = useDraftStoriesStore();

const title = ref("Storyque");

const goBack = () => {
  if (
    isUpdatedStoryPages(
      props.draftStoryOriginal.pages,
      props.draftStoryInProgress.pages,
    ) ||
    props.draftStoryOriginal.sharedStatus !==
      props.draftStoryInProgress.sharedStatus
  ) {
    props.updatePendingDraftChangesDialogState(true);
  } else {
    handleRouting("/", router, null);
  }
};

const props = defineProps({
  updateIsProcessingPostDraft: { type: Function, required: true },
  pendingDraftChangesDialog: { type: Boolean, required: true },
  updatePendingDraftChangesDialogState: { type: Function, required: true },
  draftStoryOriginal: { type: [Object, null], required: true },
  draftStoryInProgress: { type: [Object, null], default: null, required: true },
  isProcessing: { type: Boolean, required: true },
  dialog: { type: Boolean, required: true },
});

const storyDraftId = computed(() => {
  return toRaw(props.draftStoryInProgress.id);
});

const isProcessingUpdateStoryDraftOnFirestore = ref(false);

const isStoryDraftUpdateable = computed(() => {
  return isUpdatedStoryPages(props.originalStoryPages, props.updatedStoryPages);
});

const isStoryPostable = computed(() => {
  return typeof props.draftStoryInProgress === "object"
    ? isStoryPostableFunc(props.draftStoryInProgress)
    : false;
});

const handleUpdateStoryDraftOnFirestore = async () => {
  isProcessingUpdateStoryDraftOnFirestore.value = true;
  await draftStoriesStore.postStory(storyDraftId, props.draftStoryInProgress);
  await draftStoriesStore.deleteStoryDraftFromFirestore(storyDraftId.value);
  isProcessingUpdateStoryDraftOnFirestore.value = false;
};

const handlePostStoryDraft = async () => {
  const functionName = "handlePostStoryDraft";
  console.info(componentName, functionName);
  props.updateIsProcessingPostDraft(true);

  const success = await draftStoriesStore.postStoryDraftAsStory(
    props.draftStoryInProgress,
  );

  if (!success) {
    props.updateIsProcessingPostDraft(false);
    return;
  }

  await draftStoriesStore.deleteStoryDraftFromFirestore(storyDraftId, false);
  props.updateIsProcessingPostDraft(false);
};

const handleDeleteStoryDraft = () => {
  draftStoriesStore.updateDeleteDraftDialogState(true, storyDraftId.value);
};

onMounted(() => {
  console.info(componentName, "onMounted");
});

onUnmounted(() => {
  console.info(componentName, "onUnmounted");
});
</script>
