const SharedStatus = require("@/constants/SharedStatus");
const { useFriendStore } = require("@/stores/friendStore");
const functionName = "isStoryPostable";

export default function (story) {
  console.info(functionName);
  console.debug(functionName, "story:", JSON.stringify(story));

  if (story === undefined) {
    console.debug(functionName, "story is undefined or hasn't been loaded yet");
    return false;
  }

  if (story.id === undefined) {
    console.debug(functionName, "story ID is undefined");
    return false;
  }

  if (story.creatorId === undefined) {
    console.debug(functionName, "story creator ID is undefined");
    return false;
  }

  if (story.creatorName === undefined) {
    console.debug(functionName, "story creator name is undefined");
    return false;
  }

  if (
    ![
      SharedStatus.private,
      SharedStatus.privatelyShared,
      SharedStatus.public,
    ].includes(story.sharedStatus)
  ) {
    console.debug(
      functionName,
      "story shared status is invalid:",
      story.sharedStatus,
    );
    return false;
  }

  const friendStore = useFriendStore();

  if (story.sharedStatus === SharedStatus.private) {
    if (
      story.sharedTo.length !== 1 &&
      JSON.stringify(story.sharedTo) !== JSON.stringify([story.creatorId])
    ) {
      console.debug(
        functionName,
        "story sharedTo is invalid:",
        JSON.stringify(story.sharedTo),
      );
      return false;
    }
  } else if (story.sharedStatus === SharedStatus.privatelyShared) {
    if (
      story.sharedTo.length !== 2 &&
      JSON.stringify(story.sharedTo) !==
        JSON.stringify([story.creatorId, friendStore.getFriendUserId()])
    ) {
      console.debug(
        functionName,
        "story sharedTo is invalid:",
        JSON.stringify(story.sharedTo),
      );
      return false;
    }
  } else if (story.sharedStatus === SharedStatus.public) {
    if (story.sharedTo.length !== 1 && story.sharedTo[0] !== "") {
      console.debug(
        functionName,
        "story sharedTo is invalid:",
        JSON.stringify(story.sharedTo),
      );
      return false;
    }
  }

  for (const storyPage of story.pages) {
    if (storyPage.media.url.length === 0) {
      console.debug(functionName, "story page's media URL is empty");
      return false;
    }

    if (storyPage.media.valid === false) {
      console.debug(functionName, "story page's media URL is invalid");
      return false;
    }

    if (storyPage.text.length === 0) {
      console.debug(functionName, "story page's text is empty");
      return false;
    }
  }

  console.debug(functionName, "story is postable");

  return true;
}
