<template>
  <v-app-bar-nav-icon
    v-if="!props.isEditMode"
    :data-testid="`${DataTestIds.storyCreatorView.appBar.saveDraftButton}`"
    :disabled="isDisabled"
    color="primary"
    @click="
      async () => {
        console.debug(componentName, 'SaveStoryAsDraftButton clicked');
        props.updateIsSavingDraft(true);
        const success = await uploadStoryAsDraft();
        if (success) {
          console.debug(componentName, 'Draft uploaded successfully');
          deleteDraftStoryFromLocalStorage();
          snackBarStore.displayNotification({
            message: StoryConstants.notifications.storyDraft.upload.success,
            color: 'success',
            timeout: 2500,
          });
        } else {
          console.error(componentName, 'Draft upload failed');
          snackBarStore.displayNotification({
            message: StoryConstants.notifications.storyDraft.upload.failure,
            color: 'error',
            timeout: 3000,
          });
        }
        props.updateIsSavingDraft(false);
      }
    "
  >
    <v-icon>mdi-content-save-edit</v-icon>
  </v-app-bar-nav-icon>
</template>

<script setup>
import deleteDraftStoryFromLocalStorage from "@/utils/story/deleteDraftStoryFromLocalStorage";
import uploadStoryAsDraft from "@/utils/story/uploadStoryDraftToFirestore";
import DataTestIds from "@/constants/dataTestIds";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { defineProps, computed } from "vue";

import StoryConstants from "@/constants/story";

const componentName = "SaveStoryAsDraftButton";

const snackBarStore = useSnackBarStore();

const isDisabled = computed(() => {
  const value = !props.isDraftable || props.isSavingDraft;
  console.debug(componentName, "isDisabled:", value);
  return value;
});

const props = defineProps({
  isEditMode: {
    type: Boolean,
    required: true,
  },
  isDraftable: {
    type: Boolean,
    required: true,
  }, // There has been required changes to the story so it can be saved as a draft
  isSavingDraft: {
    type: Boolean,
    required: true,
  },
  updateIsSavingDraft: {
    type: Function,
    required: true,
  },
  updateTitle: {
    type: Function,
    required: true,
  },
});
</script>
