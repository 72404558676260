<template>
  <div
    class="d-flex justify-center"
    style="display: flex; flex-direction: row"
    :data-testid="DataTestIds.storyDraftEditorView.main"
  >
    <StoryDraftEditorAppBar
      :updateIsProcessingPostDraft="updateIsProcessingPostDraft"
      :pendingDraftChangesDialog="pendingDraftChangesDialog"
      :updatePendingDraftChangesDialogState="
        updatePendingDraftChangesDialogState
      "
      :draftStoryOriginal="draftStoryOriginal"
      :draftStoryInProgress="draftStoryInProgress"
      :isProcessing="isProcessing"
      :dialog="dialog"
    />
    <div
      class="d-flex justify-center"
      :style="{
        height: `${dimenStore.containerHeight}px`,
        boxSizing: 'border-box',
      }"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
        "
      >
        <div
          class="media-container"
          style="
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
          "
        >
          <v-skeleton-loader
            v-if="draftStoryInProgress.pages?.length === 0"
            type="card"
            :style="{
              margin: '10px',
              alignItems: 'center',
              padding: '0',
              width: dimenStore.isMobile ? '95%' : '500px',
              height: dimenStore.isMobile ? '95%' : '500px',
              borderRadius: '10px',
              overflow: 'hidden',
            }"
          ></v-skeleton-loader>
          <v-btn
            v-else
            :class="`${
              draftStoryInProgress.pages[currentStoryPageIndex]?.media.valid !==
                true &&
              draftStoryInProgress.pages[currentStoryPageIndex]?.media.url
                .length === 0 &&
              `story-page-${getStoryPageBackgroundNumberByStoryPageId(
                reservedStoryPageBackgroundNumbers,
                draftStoryInProgress.pages[currentStoryPageIndex].id,
                componentName,
              )}`
            }`"
            :style="{
              margin: '10px',
              alignItems: 'center',
              padding: '0',
              width: dimenStore.isMobile ? '95%' : '500px',
              height: dimenStore.isMobile ? '95%' : '500px',
              borderRadius: '10px',
              overflow: 'hidden',
            }"
            elevation="0"
            @click="
              handleBottomSheet(
                draftStoryInProgress.pages[currentStoryPageIndex].id,
              )
            "
          >
            <v-img
              v-if="
                draftStoryInProgress.pages[currentStoryPageIndex]?.media?.url
              "
              :data-testid="`${DataTestIds.storyDraftEditorView.storyMedia.realMedia}${draftStoryInProgress.pages[currentStoryPageIndex].id}`"
              :src="draftStoryInProgress.pages[currentStoryPageIndex].media.url"
              @error="
                (event) => {
                  draftStoriesStore.setUrlValidity(
                    draftStoryId,
                    draftStoryInProgress.pages[currentStoryPageIndex].id,
                    false,
                  );
                  console.warn('Image load error:', event);
                }
              "
              @load="
                draftStoriesStore.setUrlValidity(
                  draftStoryId,
                  draftStoryInProgress.pages[currentStoryPageIndex].id,
                  true,
                )
              "
              :style="{
                boxSizing: 'border-box',
                backgroundColor: '#e9ecef',
                borderRadius: '10px',
                width: dimenStore.isMobile
                  ? `${calculateWidthPercentage(95)}px`
                  : '500px',
              }"
              inline
            />
            <v-sheet
              v-else-if="draftStoryInProgress.pages[currentStoryPageIndex]?.id"
              :data-testid="`${DataTestIds.storyDraftEditorView.storyMedia.placeholder}${draftStoryInProgress.pages[currentStoryPageIndex].id}`"
              rounded
              :style="{
                width: dimenStore.isMobile ? '95%' : '500px',
                height: dimenStore.isMobile ? '95%' : '500px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0px',
                backgroundColor: 'transparent',
              }"
            >
              <v-icon icon="mdi-image-area" size="x-large"></v-icon>
            </v-sheet>
          </v-btn>
        </div>
        <div class="text-container">
          <v-skeleton-loader
            v-if="draftStoryInProgress?.pages?.length === 0"
            type="sentences"
          ></v-skeleton-loader>
          <v-textarea
            v-else-if="draftStoryInProgress.pages[currentStoryPageIndex]?.text"
            :data-testid="`${DataTestIds.storyDraftEditorView.storyText}${draftStoryInProgress.pages[currentStoryPageIndex]?.id}`"
            v-model="draftStoryInProgress.pages[currentStoryPageIndex].text"
            placeholder="One beautiful day.."
            clearable
            persistent-clear
            @click:clear="
              resetStoryPageText(
                draftStoryInProgress.pages[currentStoryPageIndex].id,
              );
              handleBlur();
            "
            @focus="handleFocus(componentName)"
            @blur="handleBlur(componentName)"
            label="Story text"
            variant="outlined"
            counter
            maxlength="1000"
            max-width="1000px"
            :rules="[validateStoryPageText]"
            @input="
              (value) => {
                const inputValue = value.target.value;
                draftStoryInProgress.pages[currentStoryPageIndex].text =
                  inputValue;
              }
            "
          ></v-textarea>
        </div>
        <div
          class="bottom-container"
          style="
            display: flex;
            flex-direction: row;
            height: 100px;
            width: 100%;
            align-items: center;
          "
        >
          <v-skeleton-loader
            v-if="draftStoryInProgress.pages?.length === 0"
            type="table-row"
          ></v-skeleton-loader>
          <BottomHorizontalStoryPages
            v-else
            :isEditMode="false"
            :storyPages="draftStoryInProgress.pages"
            :currentStoryPageIndex="currentStoryPageIndex"
            :updateCurrentStoryPageIndex="updateCurrentStoryPageIndex"
            :handleStoryPageDragNDrop="updateStoryPageOrderAfterDragNDrop"
            :storyPageBackgroundNumbers="storyPageBackgroundNumbers"
            :reservedStoryPageBackgroundNumbers="
              reservedStoryPageBackgroundNumbers
            "
            :removeStoryPageBackgroundNumber="removeStoryPageBackgroundNumber"
            :getStoryPageBackgroundNumberByStoryPageId="
              getStoryPageBackgroundNumberByStoryPageId
            "
            :addStoryPage="addStoryPage"
            :removeStoryPage="removeStoryPage"
          />
        </div>
      </div>
    </div>
    <v-bottom-sheet
      :data-testid="`${DataTestIds.storyDraftEditorView.bottomSheet.main}`"
      v-model="isBottomSheetVisible.open"
      @input="handlebottomSheetInput"
    >
      <v-sheet style="padding: 5px">
        <p style="padding: 5px">Select media from..</p>
        <v-btn
          v-for="(button, index) in bottomSheetOptions"
          :data-testid="`${DataTestIds.storyDraftEditorView.bottomSheet.action}${index}`"
          :key="button.id"
          @click="button.action"
          :variant="button.variant"
          :color="button.color"
          style="width: 100%"
        >
          {{ button.label }}
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
    <PendingDraftStoryChangesDialog
      :dialog="pendingDraftChangesDialog"
      :draftStoryId="draftStoryId"
      :draftStoryInProgress="draftStoryInProgress"
      :updateDialogState="updatePendingDraftChangesDialogState"
      :draftStoriesStore="draftStoriesStore"
      :isProcessing="isProcessing"
      :updateIsProcessingDeleteDraft="updateIsProcessingDeleteDraft"
      :updateIsProcessingSaveDraft="updateIsProcessingSaveDraft"
      :updateIsProcessingPostDraft="updateIsProcessingPostDraft"
    />
  </div>
</template>

<script setup>
import BottomHorizontalStoryPages from "@/components/storyCreator/BottomHorizontalStoryPages.vue";
import { useDimenStore } from "@/stores/dimenStore";
import { useDraftStoriesStore } from "@/stores/draftStoriesStore";
import pasteFromClipboard from "@/utils/story/pasteFromClipboard";
import { computed, ref, watch, toRaw } from "vue";
import { useRoute } from "vue-router";
// import DeleteDraftDialog from "./DeleteDraftDialog.vue";
import PendingDraftStoryChangesDialog from "./PendingDraftStoryChangesDialog.vue";
import StoryDraftEditorAppBar from "./StoryDraftEditorAppBar.vue";
import DataTestIds from "@/constants/dataTestIds";
import {
  getStoryPageBackgroundNumber,
  getStoryPageBackgroundNumberByStoryPageId,
  removeStoryPageBackgroundNumber,
} from "@/utils/story/storyPagePlaceholderBackground";
import { v4 as uuidv4 } from "uuid";
import { handleFocus, handleBlur } from "@/utils/handleFocusAndBlur";
import validateStoryPageText from "@/utils/story/validateStoryPageText";

const componentName = "StoryDraftEditor";

const route = useRoute();
const dimenStore = useDimenStore();
const draftStoriesStore = useDraftStoriesStore();

const isBottomSheetVisible = ref({ open: false, targetStoryPageId: null });

const handlebottomSheetInput = (isOpen) => {
  const functionName = "handlebottomSheetInput";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "isOpen:", isOpen);

  if (!isOpen && isBottomSheetVisible.value.open !== false) {
    isBottomSheetVisible.value = {
      open: false,
      targetStoryPageId: null,
    };
  }
};

const handleBottomSheet = async (storyPageId) => {
  const functionName = "handleBottomSheet";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "storyPageId:", storyPageId);

  isBottomSheetVisible.value = {
    open: true,
    targetStoryPageId: storyPageId,
  };
};

const storyPageBackgroundNumbers = [0, 1, 2, 3, 4];
const reservedStoryPageBackgroundNumbers = ref([]);

const dialog = computed(() => {
  return (
    draftStoriesStore.deleteDraftDialog.open || pendingDraftChangesDialog.value
  );
});

const isProcessing = computed(() => {
  return (
    draftStoriesStore.deleteDraftDialog.isProcessing ||
    isProcessingSaveDraft.value ||
    isProcessingPostDraft.value
  );
});

const pendingDraftChangesDialog = ref(false);
const updatePendingDraftChangesDialogState = (newDialogState) => {
  pendingDraftChangesDialog.value = newDialogState;
};

const isProcessingDeleteDraft = ref(false);
const updateIsProcessingDeleteDraft = (newIsProcessingState) => {
  isProcessingDeleteDraft.value = newIsProcessingState;
};

const isProcessingSaveDraft = ref(false);
const updateIsProcessingSaveDraft = (newIsProcessingState) => {
  isProcessingSaveDraft.value = newIsProcessingState;
};

const isProcessingPostDraft = ref(false);
const updateIsProcessingPostDraft = (newIsProcessingState) => {
  isProcessingPostDraft.value = newIsProcessingState;
};

const currentStoryPageIndex = ref(0);
const updateCurrentStoryPageIndex = (index) => {
  currentStoryPageIndex.value = index;
};

const draftStoryId = route.params.draftId ?? null;
const draftStory = draftStoriesStore.stories.find(
  (story) => story.id === draftStoryId,
);
const draftStoryOriginal = toRaw(draftStory);
const draftStoryInProgress = ref(toRaw(draftStory));
watch(
  () => draftStoryInProgress.value,
  (newDraftStoryInProgress) => {
    console.debug(
      componentName,
      "draftStoryInProgress watcher",
      "newDraftStoryInProgress:",
      newDraftStoryInProgress,
    );

    newDraftStoryInProgress.pages.forEach((storyPage) => {
      if (storyPage?.number === undefined) {
        storyPage.number = getStoryPageBackgroundNumber(
          storyPageBackgroundNumbers,
          reservedStoryPageBackgroundNumbers,
          storyPage.id,
        );
      }
    });
  },
  { immediate: true },
);

const bottomSheetOptions = [
  {
    id: 0,
    label: "Paste link",
    color: "black",
    variant: "outlined",
    action: async () => {
      await pasteFromClipboard(
        draftStoriesStore,
        isBottomSheetVisible.value.targetStoryPageId,
      );
      await draftStoriesStore.checkGifUrl(
        isBottomSheetVisible.value.targetStoryPageId,
      );
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
  {
    id: 1,
    label: "Reset",
    color: "error",
    variant: "text",
    action: () => {
      draftStoriesStore.resetStoryPageMedia(
        isBottomSheetVisible.value.targetStoryPageId,
      );
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
];

const resetStoryPageText = (storyPageId) => {
  const functionName = "resetStoryPageText";
  console.info(componentName, functionName);

  draftStoryInProgress.value.pages.forEach((page) => {
    if (page.id === storyPageId) {
      page.text.value = "";
    }
  });
};

const addStoryPage = (newStoryPageIndex) => {
  const functionName = "addStoryPage";
  console.info(componentName, functionName);
  console.debug(
    componentName,
    functionName,
    "newStoryPageIndex:",
    newStoryPageIndex,
  );

  const storyPageId = uuidv4();

  const newStoryPage = {
    id: storyPageId,
    text: "",
    media: {
      url: "",
      valid: null,
    },
    number: getStoryPageBackgroundNumber(
      storyPageBackgroundNumbers,
      reservedStoryPageBackgroundNumbers,
      storyPageId,
      componentName,
    ),
  };

  console.debug(
    componentName,
    functionName,
    "newStoryPage:",
    JSON.stringify(newStoryPage),
  );

  draftStoryInProgress.value.pages = [
    ...draftStoryInProgress.value.pages.slice(0, newStoryPageIndex),
    newStoryPage,
    ...draftStoryInProgress.value.pages.slice(newStoryPageIndex),
  ];
};

const removeStoryPage = (storyPageId) => {
  const functionName = "removeStoryPage";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "storyPageId:", storyPageId);

  const newPages = draftStoryInProgress.value.pages.filter(
    (page) => page.id !== storyPageId,
  );
  console.debug(
    componentName,
    functionName,
    "newPages:",
    JSON.stringify(newPages),
  );
  draftStoryInProgress.value.pages = newPages;
};

const updateStoryPageOrderAfterDragNDrop = (event) => {
  const functionName = "updateStoryPageOrderAfterDragNDrop";
  console.info(componentName, functionName);

  if (event.oldIndex === -1 || event.newIndex === -1) {
    console.error(
      componentName,
      functionName,
      "Invalid page index after drag-n-drop",
    );
    return;
  }

  console.debug(
    componentName,
    functionName,
    "oldIndex:",
    event.oldIndex,
    "newIndex:",
    event.newIndex,
  );

  const pageObj = draftStoryInProgress.value.pages[event.oldIndex];
  // Remove the object from its current position
  draftStoryInProgress.value.pages.splice(event.oldIndex, 1);

  draftStoryInProgress.value.pages.splice(event.newIndex, 0, pageObj);
};

// Preserve state across HMR updates
if (module.hot) {
  module.hot.accept();
  module.hot.dispose((data) => {
    data.draftStoryOriginal = draftStoryOriginal.value;
    data.draftStoryInProgress = draftStoryInProgress.value;
    data.draftStory = draftStory.value;
  });
  if (module.hot.data) {
    draftStoryOriginal.value = module.hot.data.draftStoryOriginal;
    draftStoryInProgress.value = module.hot.data.draftStoryInProgress;
    draftStory.value = module.hot.data.draftStory;
  }
}
</script>

<style scoped>
p br::after {
  content: "\A"; /* Using a generated content with '\A' for a newline */
  white-space: pre; /* Preserve whitespace to ensure the newline is rendered */
}

.create-story-pages-list {
  width: 500px;
}

.v-card {
  text-align: left;
}

.v-card-item__content {
  padding-top: 10px;
}

.v-input {
  margin-bottom: 10px;
}

.panel-header {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.panel-content {
  display: none;
  padding: 10px;
}

.horizontal-scroll-item {
  align-self: center;
  justify-content: center;
  height: 100%;
  margin-right: 25px;
  border-radius: 10px;
  overflow: hidden;
}

.img-with-margin {
  margin: 10px; /* Adjust margin as needed */
  width: 500px; /* Subtract the horizontal margins from the width */
  height: 500px; /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.bottom-container {
  flex: 0.5;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%; /* Subtract the horizontal margins from the width */
  height: calc(100% - 20px); /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.text-container {
  flex: 1;
  margin: 10px; /* Adjust margin as needed */
  width: calc(100% - 20px); /* Subtract the horizontal margins from the width */
  height: calc(100% - 20px); /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
</style>

<style
  lang="css"
  scoped
  src="../../components/storyCreator/storyCreatorViewStoryPageBackgrounds.css"
></style>
