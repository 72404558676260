<template>
  <v-app id="app">
    <v-main>
      <v-container fluid>
        <template
          v-if="loadingScreenStore.isLoading == false && authStore.initialized"
        >
          <router-view />
          <DeveloperMenu />
        </template>
        <template v-else>
          <LoadingScreen />
        </template>
      </v-container>
      <v-dialog
        max-width="500"
        v-model="notificationPermissionDialog"
        transition="dialog-top-transition"
      >
        <template v-slot:default="{ isActive }">
          <v-card title="Notifications">
            <v-card-text>
              <p style="display: inline">
                Grant Storyque notification permissions?
              </p>

              <p
                v-if="fcmStore.notificationPermissionGranted == false"
                style="white-space: pre-wrap"
                v-html="getNotificationPermission()"
              ></p>
            </v-card-text>

            <v-card-actions>
              <template
                v-if="fcmStore.notificationPermissionGranted == undefined"
              >
                <v-btn
                  color="primary"
                  variant="tonal"
                  @click="
                    async () => {
                      await fcmStore.requestNotificationPermission();
                      isActive.value = false;
                      notificationStore.removeNotification(
                        'requestNotificationPermission',
                      );
                    }
                  "
                  ><v-icon icon="mdi-check"></v-icon
                ></v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  text="Close"
                  variant="text"
                  color="error"
                  @click="isActive.value = false"
                  ><v-icon icon="mdi-close"></v-icon
                ></v-btn>
              </template>
              <template v-if="fcmStore.notificationPermissionGranted == false">
                <v-spacer></v-spacer>
                <v-btn
                  text="OK"
                  variant="tonal"
                  color="primary"
                  @click="isActive.value = false"
                  ><v-icon icon="mdi-check"></v-icon
                ></v-btn>
                <v-spacer></v-spacer>
              </template>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog
        max-width="500"
        v-model="devicesStore.emailVerificationDialog"
        transition="dialog-fade-transition"
      >
        <template v-slot:default="">
          <v-card title="Email verification">
            <v-tabs v-model="emailVerificationTabs" fixed-tabs>
              <v-tab value="email" readonly>Email</v-tab>
              <v-tab value="code" readonly>Code</v-tab>
            </v-tabs>
            <v-card-text>
              <v-tabs-window v-model="emailVerificationTabs">
                <v-tabs-window-item value="email">
                  <v-text-field
                    v-model="emailInput"
                    width="95%"
                    clearable
                    label="Email"
                    prepend-icon="mdi-email-outline"
                    :rules="[validateEmail]"
                  ></v-text-field>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 5px;
                    "
                  >
                    <v-btn
                      :disabled="
                        emailInput?.length == 0 ||
                        emailInputErrors?.length > 0 ||
                        processingSendingEmail
                      "
                      @click="
                        async () => {
                          if (!devicesStore.emailVerified) {
                            await handleVerificationCodeRequest();
                          }
                        }
                      "
                      color="primary"
                      variant="tonal"
                    >
                      <v-progress-circular
                        v-if="processingSendingEmail"
                        indeterminate
                        size="20"
                        width="3"
                      ></v-progress-circular>
                      <v-icon v-else icon="mdi-send"></v-icon>
                    </v-btn>
                  </div>
                </v-tabs-window-item>

                <v-tabs-window-item value="code"
                  ><v-otp-input
                    v-model="verificationCode"
                    :loading="processingSendingVerificationCode"
                    length="6"
                    variant="outlined"
                  ></v-otp-input>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 5px;
                    "
                  >
                    <v-btn
                      :disabled="
                        verificationCode.length < 6 ||
                        processingSendingVerificationCode
                      "
                      @click="
                        async () => {
                          if (await handleVerificationCodeVerification()) {
                            devicesStore.emailVerificationDialog = false;
                            await unlinkAllDevices();
                          }
                        }
                      "
                      color="primary"
                      variant="tonal"
                    >
                      <v-progress-circular
                        v-if="processingSendingVerificationCode"
                        indeterminate
                        size="20"
                        width="3"
                      ></v-progress-circular>
                      <v-icon v-else icon="mdi-check"></v-icon>
                    </v-btn>
                  </div>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog
        max-width="500"
        v-model="devicesStore.unlinkAllDevicesDialog"
        transition="dialog-fade-transition"
        ><template v-slot:default="{ isActive }">
          <v-card title="Unlink all devices">
            <v-card-text
              >You sure you want to unlink all devices from your
              account?</v-card-text
            >
            <v-card-actions>
              <v-btn color="primary" variant="tonal" @click="unlinkAllDevices"
                ><v-icon>mdi-check</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isActive.value = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
          </v-card></template
        >
      </v-dialog>
      <v-dialog
        data-testid="friend-request-dialog"
        max-width="500"
        v-model="friendStore.friendRequestDialog"
        transition="dialog-fade-transition"
        ><template v-slot:default="{ isActive }">
          <v-card
            title="Friend request"
            :prepend-icon="
              friendStore.isPendingFriendRequest
                ? 'mdi-account-cancel'
                : 'mdi-account-plus'
            "
          >
            <v-card-text data-testid="friend-request-dialog-text">{{
              friendStore.getFriendRequestDialogText()
            }}</v-card-text>
            <v-card-actions>
              <v-btn
                :data-testid="`friend-request-dialog-action-button-${
                  friendStore.isPendingFriendRequest ? 'delete' : 'create'
                }-request`"
                color="primary"
                variant="tonal"
                @click="
                  () => {
                    friendStore.isPendingFriendRequest
                      ? friendStore.deleteFriendRequest()
                      : friendStore.createFriendRequest();
                    isActive.value = false;
                    friendStore.friendRequestDialog = false;
                  }
                "
                ><v-icon>mdi-check</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                data-testid="friend-request-dialog-action-cancel"
                color="error"
                @click="isActive.value = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
          </v-card></template
        >
      </v-dialog>
    </v-main>
    <BottomNavigation />
    <SnackBar />
    <DeleteDraftDialog />
  </v-app>
</template>

<script setup>
import { usePwaStore } from "@/stores/pwaStore";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useTheme } from "vuetify";
import BottomNavigation from "./components/BottomNavigation.vue";
import SnackBar from "./components/SnackBar.vue";
import { useAuthStore } from "./stores/authStore";
import { useDevicesStore } from "./stores/devicesStore";
import { useDimenStore } from "./stores/dimenStore";
import { useFcmStore } from "./stores/fcmStore";
import { useFriendStore } from "./stores/friendStore";
import { useLoadingScreenStore } from "./stores/loadingScreenStore";
import { useMyStoriesStore } from "./stores/myStoriesStore";
import { useSnackBarStore } from "./stores/snackBarStore";
import { useVisibilityStore } from "./stores/visibilityStore";
import LoadingScreen from "./views/LoadingScreen.vue";
import checkVerificationCode from "./utils/access/checkVerificationCode";
import getVerificationCode from "./utils/access/getVerificationCode";
import getNotificationPermission from "@/utils/getNotificationPermissionEnableGuide";
import { useNotificationStore } from "./stores/notificationStore";
import { useStoriesSharedWithYou } from "./stores/storiesSharedWithYouStore";
import { useNsfwStore } from "./stores/nsfwStore";
import { useDraftStoriesStore } from "./stores/draftStoriesStore";
import { useHead } from "@vueuse/head";
import DeveloperMenu from "./components/DeveloperMenu.vue";
import DeleteDraftDialog from "./components/storyDraftEditor/DeleteDraftDialog.vue";

useHead({
  title: "Storyque",
  meta: [
    { name: "description", content: "Bring your stories to life" },
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=scale",
    },
    { name: "mobile-web-app-capable", content: "yes" },
    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "apple-mobile-web-app-title", content: "Storyque" },
    {
      name: "apple-mobile-web-app-status-bar-style",
      content: "black-translucent",
    },
  ],
});

const componentName = "App";

console.debug(componentName, "setup");

const router = useRouter();

const friendStore = useFriendStore();
friendStore.onInit();

const notificationStore = useNotificationStore();
notificationStore.onInit();

const storiesSharedWithYouStore = useStoriesSharedWithYou();
storiesSharedWithYouStore.onInit();

const devicesStore = useDevicesStore();
devicesStore.onInit();

const draftStoriesStore = useDraftStoriesStore();
draftStoriesStore.onInit();

// In case user has not yet verified their email address
const emailVerificationTabs = ref(null);

const emailInput = ref("");
const emailInputErrors = ref([]);

const processingEmail = ref(false);

watch(
  () => devicesStore.emailVerificationDialog,
  (newEmailVerificationDialogValue) => {
    if (!newEmailVerificationDialogValue) {
      // Reset email verification dialog when it's closed
      processingEmail.value = false;
      emailVerificationTabs.value = "email";
      emailInput.value = "";
      verificationCodeInput.value = "";
      processingVerificationCode.value = false;
    }
  },
  { immediate: false, deep: false },
);

const verificationCodeInput = ref("");
const processingVerificationCode = ref(false);

const pwaStore = usePwaStore();
pwaStore.onInit();

const nsfwStore = useNsfwStore();
nsfwStore.onInit();

const loadingScreenStore = useLoadingScreenStore();
loadingScreenStore.onInit();

const authStore = useAuthStore();
authStore.onInit();

const snackBarStore = useSnackBarStore();
snackBarStore.onInit();

const visibilityStore = useVisibilityStore();
visibilityStore.onInit();

const fcmStore = useFcmStore();
fcmStore.onInit();

const myStoriesStore = useMyStoriesStore();
myStoriesStore.onInit();

const dimenStore = useDimenStore();
dimenStore.initStore();

const notificationPermissionDialog = ref(false);

const vTheme = useTheme();
const errorColor = vTheme.current.value.colors.error + "80";

const friendButtonIcon = ref(null);
const friendButtonBackgroundColor = ref("");
const friendButtonHover = ref(false);

watch(
  () => [
    friendStore.acceptedFriendRequest,
    friendStore.isPendingFriendRequest,
    friendStore.isProcessingResponse,
    friendButtonHover.value,
  ],
  ([
    newAcceptedFriendRequest,
    newIsPendingFriendRequest,
    newIsProcessingResponse,
    newFriendButtonHover,
  ]) => {
    console.debug(
      componentName,
      "newAcceptedFriendRequest:",
      newAcceptedFriendRequest,
      "newIsPendingFriendRequest:",
      newIsPendingFriendRequest,
      "newIsProcessingResponse:",
      newIsProcessingResponse,
      "newFriendButtonHover:",
      newFriendButtonHover,
    );

    if (newFriendButtonHover) {
      if (!newIsProcessingResponse) {
        if (newIsPendingFriendRequest) {
          friendButtonBackgroundColor.value = errorColor;
          friendButtonIcon.value = "mdi-account-remove";
        } else {
          friendButtonBackgroundColor.value = "";
          friendButtonIcon.value = "mdi-account-plus";
        }
      }
    } else {
      if (!newIsProcessingResponse) {
        if (newIsPendingFriendRequest) {
          friendButtonBackgroundColor.value = "";
          friendButtonIcon.value = "mdi-account-clock";
        } else {
          friendButtonBackgroundColor.value = "";
          friendButtonIcon.value = "mdi-account-plus";
        }
      }
    }
  },
  { immediate: true, deep: false },
);

const resetEmailVerificationDialog = () => {
  devicesStore.emailVerificationDialog = false;
  emailInput.value = "";
  emailInputErrors.value = [];
  processingEmail.value = false;
  verificationCodeInput.value = "";
  processingVerificationCode.value = false;
};

const validateEmail = (value) => {
  const functionName = "validateEmail";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "value:", value);

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  emailInputErrors.value = re.test(value.toLowerCase())
    ? []
    : ["Invalid email address"];
  console.debug(
    componentName,
    functionName,
    "regex result:",
    emailInputErrors.value,
  );

  if (emailInputErrors.value.length > 0) {
    return emailInputErrors.value[0];
  } else {
    return true;
  }
};

const handleVerificationCodeRequest = async () => {
  const functionName = "handleVerificationCodeRequest";
  console.info(componentName, functionName);

  devicesStore.emailVerificationDialog = true;

  processingEmail.value = true;

  const success = await getVerificationCode(authStore.userId, emailInput.value);

  processingEmail.value = false;

  console.debug(componentName, functionName, "success:", success);

  if (success) {
    // Switch from email input to code input on successful code creation
    emailVerificationTabs.value = "code";
  }
};

const handleVerificationCodeVerification = async () => {
  const functionName = "handleVerificationCodeVerification";
  console.info(componentName, functionName);

  processingVerificationCode.value = true;

  const response = await checkVerificationCode(
    authStore.userId,
    emailInput.value,
    verificationCodeInput.value,
  );

  processingVerificationCode.value = false;

  console.debug(componentName, functionName, "response:", response);

  if (response.status == 200) {
    resetEmailVerificationDialog();
    snackBarStore.displayNotification({
      message: "Email successfully verified",
      color: "success",
      timeout: 2000,
    });
  } else if (response.status == 404) {
    snackBarStore.displayNotification({
      message: "Invalid code",
      color: "error",
      timeout: 2000,
    });
  } else {
    snackBarStore.displayNotification({
      message: "Error occurred when verifying code",
      color: "error",
      timeout: 2000,
    });
  }
};

const checkClipboardPermissions = async () => {
  try {
    const { state: writeState } = await navigator.permissions.query({
      name: "clipboard-write",
    });
    const { state: readState } = await navigator.permissions.query({
      name: "clipboard-read",
    });

    if (writeState === "granted" && readState === "granted") {
      console.log("Clipboard permissions granted");
    } else {
      console.log("Requesting clipboard permissions");
      await navigator.clipboard.writeText(""); // Request write permission
      await navigator.clipboard.readText(); // Request read permission
    }
  } catch (error) {
    console.error("Error checking clipboard permissions:", error);
  }
};

onMounted(async () => {
  // Listen for the appinstalled event
  window.addEventListener("appinstalled", pwaStore.handleAppInstalled);

  window.addEventListener("resize", () => {
    dimenStore.calculateContainerDynamicHeight(router.currentRoute.value.meta);
  });

  // Prevent default context menu on long-press except for specific elements
  document.addEventListener(
    "contextmenu",
    function (event) {
      if (!event.target.closest(".v-input")) {
        event.preventDefault();
      }
    },
    false,
  );

  await checkClipboardPermissions();

  fcmStore.getNotificationPermission();
});

onUnmounted(() => {
  window.removeEventListener("appinstalled", pwaStore.handleAppInstalled);

  window.removeEventListener("resize", () => {
    dimenStore.calculateContainerDynamicHeight(router.currentRoute.value.meta);
  });

  document.removeEventListener(
    "contextmenu",
    function (event) {
      event.preventDefault();
    },
    false,
  );
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

html,
body {
  overflow: hidden;
  width: 100vw;
}

.v-container {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.v-responsive__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-list-item-title {
  text-align: center;
}

.v-list-item-title:hover {
  background-color: #e9ecefbf;
  border-radius: 10px;
}

.disabled-title {
  pointer-events: none; /* Disable clicking */
  user-select: none; /* Disable highlighting */
}

* {
  -webkit-touch-callout: none; /* Disable callout, image save panel (iOS) */
  -webkit-user-select: none; /* Disable text selection (iOS) */
  -khtml-user-select: none; /* Disable text selection (Konqueror) */
  -moz-user-select: none; /* Disable text selection (Firefox) */
  -ms-user-select: none; /* Disable text selection (IE/Edge) */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  touch-action: manipulation; /* Disable double-tap-to-zoom (iOS) */
}
</style>
