<template>
  <v-app-bar app :elevation="2" rounded density="compact">
    <template v-slot:prepend>
      <v-app-bar-nav-icon
        @click="goBack"
        :data-testid="`${DataTestIds.storyCreatorView.backButton}`"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title
        :style="{
          fontWeight:
            title === StoryConstant.appBarTitle.default ? 'bold' : 'lighter',
        }"
        :data-testid="`${DataTestIds.storyCreatorView.appBarTitle}`"
        >{{ title }}</v-app-bar-title
      >
    </template>
    <template v-slot:append>
      <StoryShareStatusDial
        :isEditMode="isEditMode"
        :sharedStatus="sharedStatus"
      />
      <ResetStoryButton :isEditMode="isEditMode" />
      <SaveStoryAsDraftButton
        :isEditMode="isEditMode"
        :isDraftable="isDraftable"
        :isSavingDraft="isSavingDraft"
        :updateIsSavingDraft="updateIsSavingDraft"
        :updateTitle="updateTitle"
      />
      <v-app-bar-nav-icon
        data-testid="post-or-update-button"
        color="primary"
        :disabled="isStoryPostable == false"
        @click="
          async () => {
            if (route.name == 'storyEditor') {
              await editStoryStore.updateStory();
              goBack();
            } else {
              await newStoryStore.postStory();
            }
          }
        "
      >
        <v-icon
          v-if="
            isEditMode
              ? editStoryStore.isPosting == false
              : newStoryStore.posting == false
          "
          >{{
            route.name == "storyEditor" ? "mdi-content-save" : "mdi-send"
          }}</v-icon
        >
        <v-progress-circular v-else color="primary" indeterminate />
      </v-app-bar-nav-icon>
    </template>
  </v-app-bar>
  <div
    data-testid="story-creator-view"
    class="d-flex justify-center"
    :style="{
      height: `${dimenStore.containerHeight}px`,
      boxSizing: 'border-box',
    }"
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
      "
    >
      <div
        class="media-container"
        style="
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        "
      >
        <v-skeleton-loader
          v-if="storyPages.length == 0"
          type="image"
          :style="{
            margin: '10px',
            alignItems: 'center',
            padding: '0',
            width: dimenStore.isMobile ? '95%' : '500px',
            height: dimenStore.isMobile ? '95%' : '500px',
            borderRadius: '10px',
            overflow: 'hidden',
          }"
        ></v-skeleton-loader>
        <v-btn
          v-else
          :class="`${
            !storyPages[currentStoryPageIndex]?.media?.valid
              ? `story-page-${getStoryPageBackgroundNumberByStoryPageId(
                  reservedStoryPageBackgroundNumbers,
                  storyPages[currentStoryPageIndex].id,
                  componentName,
                )}`
              : ''
          }`"
          :style="{
            margin: '10px',
            alignItems: 'center',
            padding: '0',
            width: dimenStore.isMobile ? '95%' : '500px',
            height: dimenStore.isMobile ? '95%' : '500px',
            borderRadius: '10px',
            overflow: 'hidden',
          }"
          elevation="0"
          @click="handleBottomSheet(storyPages[currentStoryPageIndex].id)"
        >
          <v-img
            v-if="storyPages[currentStoryPageIndex]?.media?.url"
            :data-testid="`${DataTestIds.storyCreatorView.storyMedia.realMedia}${storyPages[currentStoryPageIndex].id}`"
            :src="storyPages[currentStoryPageIndex].media.url"
            @error="
              storyStore.setUrlValidity(
                storyPages[currentStoryPageIndex].id,
                false,
              )
            "
            @load="
              storyStore.setUrlValidity(
                storyPages[currentStoryPageIndex].id,
                true,
              )
            "
            @loadStart="
              () => {
                console.debug(
                  componentName,
                  'starting to load media from URL:',
                  storyPages[currentStoryPageIndex].media.url,
                );
              }
            "
            :style="{
              boxSizing: 'border-box',
              backgroundColor: '#e9ecef',
              borderRadius: '10px',
              width: dimenStore.isMobile
                ? `${calculateWidthPercentage(95)}px`
                : '500px',
            }"
            inline
          />
          <v-sheet
            v-else
            :data-testid="`${DataTestIds.storyCreatorView.storyMedia.placeholder}${storyPages[currentStoryPageIndex].id}`"
            rounded
            :style="{
              width: dimenStore.isMobile ? '95%' : '500px',
              height: dimenStore.isMobile ? '95%' : '500px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0px',
              backgroundColor: 'transparent',
            }"
          >
            <v-icon icon="mdi-image-area" size="x-large"></v-icon>
          </v-sheet>
        </v-btn>
      </div>
      <div class="text-container">
        <v-skeleton-loader
          v-if="storyPages.length == 0"
          type="sentences"
        ></v-skeleton-loader>
        <v-textarea
          v-else
          :data-testid="`${DataTestIds.storyCreatorView.storyText}${storyPages[currentStoryPageIndex]?.id}`"
          v-model="storyPages[currentStoryPageIndex].text"
          placeholder="One beautiful day.."
          clearable
          persistent-clear
          @click:clear="
            storyStore.resetStoryPageText(storyPages[currentStoryPageIndex].id);
            handleBlur();
          "
          @focus="handleFocus(componentName)"
          @blur="handleBlur(componentName)"
          label="Story text"
          variant="outlined"
          counter
          maxlength="1000"
          max-width="1000px"
          :rules="[validateStoryPageText]"
          @input="
            (value) => {
              const inputValue = value.target.value;
              updateStoryPageText(
                storyPages[currentStoryPageIndex].id,
                inputValue,
              );
            }
          "
        ></v-textarea>
      </div>
      <div
        class="bottom-container"
        style="
          display: flex;
          flex-direction: row;
          height: 100px;
          width: 100%;
          align-items: center;
        "
      >
        <BottomHorizontalStoryPages
          :isEditMode="isEditMode"
          :storyPages="storyPages"
          :currentStoryPageIndex="currentStoryPageIndex"
          :updateCurrentStoryPageIndex="updateCurrentStoryPageIndex"
          :handleStoryPageDragNDrop="handleStoryPageDragNDrop"
          :storyPageBackgroundNumbers="storyPageBackgroundNumbers"
          :reservedStoryPageBackgroundNumbers="
            reservedStoryPageBackgroundNumbers
          "
          :removeStoryPageBackgroundNumber="removeStoryPageBackgroundNumber"
          :getStoryPageBackgroundNumberByStoryPageId="
            getStoryPageBackgroundNumberByStoryPageId
          "
          :addStoryPage="newStoryStore.addStoryPage"
          :removeStoryPage="newStoryStore.deleteStoryPage"
        />
      </div>
    </div>
  </div>
  <v-bottom-sheet
    data-testid="storyCreatorView-bottom-sheet"
    v-model="isBottomSheetVisible.open"
    @input="handlebottomSheetInput"
  >
    <v-sheet style="padding: 5px">
      <p style="padding: 5px">Select media from..</p>
      <v-btn
        v-for="(button, index) in bottomSheetOptions"
        :data-testid="`bottom-sheet-action#${index}`"
        :key="button.id"
        @click="button.action"
        :variant="button.variant"
        :color="button.color"
        style="width: 100%"
      >
        {{ button.label }}
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
  <PendingStoryChangesDialog :cancelDraftDelay="cancelDraftDelay" />
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { useDimenStore } from "@/stores/dimenStore";
import { useNewStoryStore } from "../stores/newStoryStore";
import {
  onMounted,
  onUnmounted,
  computed,
  ref,
  watch,
  onBeforeMount,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useFriendStore } from "@/stores/friendStore";
import { useEditStoryStore } from "@/stores/editStoryStore";
import { useMyStoriesStore } from "@/stores/myStoriesStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import StoryShareStatusDial from "@/components/storyCreator/StoryShareStatusDial.vue";
import BottomHorizontalStoryPages from "@/components/storyCreator/BottomHorizontalStoryPages.vue";
import SaveStoryAsDraftButton from "@/components/storyCreator/SaveStoryAsDraftButton.vue";
import PendingStoryChangesDialog from "@/components/storyCreator/PendingStoryChangesDialog.vue";
import {
  getStoryPageBackgroundNumber,
  getStoryPageBackgroundNumberByStoryPageId,
  removeStoryPageBackgroundNumber,
} from "@/utils/story/storyPagePlaceholderBackground";
import { handleFocus, handleBlur } from "@/utils/handleFocusAndBlur";
import validateStoryPageText from "@/utils/story/validateStoryPageText";
import handleSaveStoryToLocalStorage from "@/utils/story/handleSaveStoryToLocalStorage";
import saveDraftDelayTimestampInTesting from "@/utils/story/draft/saveDraftDelayTimestampInTesting";
import ResetStoryButton from "@/components/storyCreator/ResetStoryButton.vue";

// Constants
import StoryConstant from "@/constants/story";
import DataTestIds from "@/constants/dataTestIds";
import deleteDraftStoryFromLocalStorage from "@/utils/story/deleteDraftStoryFromLocalStorage";
import StoryDraftLoadingStatus from "@/constants/StoryDraftLoadingStatus";

import isUUID from "validator/lib/isUUID";
import { useDialogStore } from "@/stores/dialogStore";

const componentName = "StoryCreatorView";

const router = useRouter();
const route = useRoute();

const myStoriesStore = useMyStoriesStore();
const snackBarStore = useSnackBarStore();
const dimenStore = useDimenStore();
const newStoryStore = useNewStoryStore();
const editStoryStore = useEditStoryStore();
const friendStore = useFriendStore();
const dialogStore = useDialogStore();

const isEditMode = ref(route.params?.postId !== undefined);
watch(
  () => isEditMode.value,
  (newIsEditModeState) => {
    console.debug(componentName, "isEditMode:", newIsEditModeState);
    if (newIsEditModeState) {
      console.debug(componentName, "postId in URL:", route.params.postId);
      editStoryStore.populateStoryToBeEdited(route.params.postId);
    }
  },
  { immediate: false, deep: false },
);

const storyStore = computed(() => {
  const functionName = "storyStore[computed]";
  if (isEditMode.value) {
    console.debug(
      componentName,
      functionName,
      "isEditMode; Using editStoryStore",
    );
    return editStoryStore;
  } else {
    console.debug(
      componentName,
      functionName,
      "not in edit-mode; Using newStoryStore",
    );
    return newStoryStore;
  }
});
const updateStoryPageText = (id, text) => {
  storyStore.value.updateStoryPageText(id, text);
};

// Story objects for comparison
const initialStoryObj = ref(null);
watch(
  () => initialStoryObj.value,
  (newInitialStoryObj) => {
    console.debug(
      componentName,
      "initialStoryObj:",
      JSON.stringify(newInitialStoryObj),
    );
  },
  { immediate: false, deep: false },
);
const lastSavedStoryObj = ref(null);
const currentStoryObj = ref({
  sharedStatus: storyStore.value.sharedStatus,
  pages: storyStore.value.pages,
});

// Story object which is loaded from localStorage
const draftLoadedFromLocalStorage = ref(null);
const storyDraftLoadingStatus = ref(StoryDraftLoadingStatus.none);

const isValidStory = (story) => {
  return typeof story === "object" && Object.keys(story).length > 0;
};

const currentStoryPageIndex = ref(0);
function updateCurrentStoryPageIndex(newIndex) {
  currentStoryPageIndex.value = newIndex;
}
watch(
  () => currentStoryPageIndex.value,
  (newCurrentStoryPageIndex) => {
    console.debug(
      componentName,
      "newCurrentStoryPageIndex:",
      newCurrentStoryPageIndex,
    );
  },
  { immediate: false, deep: false },
);

const isBottomSheetVisible = ref({ open: false, targetStoryPageId: null });

watch(
  () => isBottomSheetVisible.value,
  (newIsBottomSheetVisible) => {
    Object.keys(newIsBottomSheetVisible).forEach((key) => {
      console.log(
        componentName,
        `newIsBottomSheetVisible, key: ${key}, value: ${newIsBottomSheetVisible[key]}`,
      );
    });
  },
  { immediate: false, deep: false },
);

const bottomSheetOptions = [
  {
    id: 0,
    label: "Paste link",
    color: "black",
    variant: "outlined",
    action: async () => {
      await pasteFromClipboard(isBottomSheetVisible.value.targetStoryPageId);
      await newStoryStore.checkGifUrl(
        isBottomSheetVisible.value.targetStoryPageId,
      );
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
  {
    id: 1,
    label: "Reset",
    color: "error",
    variant: "text",
    action: () => {
      newStoryStore.resetStoryPageMedia(
        isBottomSheetVisible.value.targetStoryPageId,
      );
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
];

const sharedStatus = computed(() => {
  return isEditMode.value
    ? editStoryStore.sharedStatus
    : newStoryStore.sharedStatus;
});
watch(
  sharedStatus,
  (newSharedStatus) => {
    console.debug(componentName, "sharedStatus changed:", newSharedStatus);
  },
  { immediate: true, deep: false },
);

const isEmptyStory = () => {
  const functionName = "isEmptyStory";
  console.info(componentName, functionName);

  if (storyStore.value.pages.length === 1) {
    let isEmptyStoryPage = false;
    const emptyStoryPage = storyStore.value.pages[0];
    if (
      emptyStoryPage.media.url === "" &&
      emptyStoryPage.text === "" &&
      emptyStoryPage.media.valid === null
    ) {
      console.debug(componentName, functionName, "Story is empty");
      isEmptyStoryPage = true;
    }
    console.debug(componentName, functionName, "Story is empty");
    if (isEmptyStoryPage) {
      return true;
    } else {
      console.debug(componentName, functionName, "Story is not empty");
      return false;
    }
  } else {
    console.debug(componentName, functionName, "Story is not empty");
    return false;
  }
};

const goBack = () => {
  const functionName = "goBack";
  console.info(componentName, functionName);

  // TODO: Handle going back and read hasBeenSvaedSinceLastChange
  if (newStoryStore.hasBeenSavedSinceLastChange) {
    console.debug(
      componentName,
      functionName,
      "Story has been saved since last change",
    );
    router.replace("/");
  } else if (isEmptyStory() === true) {
    console.debug(
      componentName,
      functionName,
      "Story is empty; Deleting draft from localStorage",
    );
    deleteDraftStoryFromLocalStorage();
    router.replace("/");
  } else if (isEditMode.value) {
    console.debug(componentName, functionName, "in edit-mode");
    router.replace("/");
  } else {
    console.debug(
      componentName,
      functionName,
      "Story has not been saved since last change",
    );
    dialogStore.updateDiscardChangesDialog(true);
  }
};

const handleBottomSheet = async (storyPageId) => {
  const functionName = "handleBottomSheet";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "storyPageId:", storyPageId);

  isBottomSheetVisible.value = {
    open: true,
    targetStoryPageId: storyPageId,
  };
};

// Handle closing BottomSheet, nullifying the temporary storing of selected story page's ID
const handlebottomSheetInput = (isOpen) => {
  const functionName = "handlebottomSheetInput";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "isOpen:", isOpen);

  if (!isOpen && isBottomSheetVisible.value.open !== false) {
    isBottomSheetVisible.value = {
      open: false,
      targetStoryPageId: null,
    };
  }
};

const pasteFromClipboard = async (storyPageId) => {
  const functionName = "pasteFromClipboard";
  console.info(componentName, functionName);

  const index = isEditMode.value
    ? editStoryStore.getPageIndexById(storyPageId)
    : newStoryStore.getPageIndexById(storyPageId);
  try {
    const link = await navigator.clipboard.readText();
    console.log(
      componentName,
      functionName,
      "index:",
      index,
      "clipboard:",
      link,
    );
    isEditMode.value
      ? (editStoryStore.pages[index].media.url = link)
      : (newStoryStore.pages[index].media.url = link);
  } catch (error) {
    console.error(error);
  }
};

const extractSharedStatusFromUrl = () => {
  const functionName = "extractSharedStatusFromUrl";
  console.info(componentName, functionName);

  const fullUrl = window.location;
  console.debug(componentName, functionName, "fullUrl:", fullUrl);

  const url = new URL(fullUrl);
  const pathname = url.pathname;
  console.debug(componentName, functionName, "pathname:", pathname);

  const segments = pathname.split("/").filter((segment) => segment !== "");

  let sharedStatus;
  try {
    sharedStatus = Number(segments[2]);
  } catch (error) {
    console.error(componentName, functionName, "sharedStatus not a number");
    sharedStatus = 0;
  }

  console.debug(componentName, functionName, `sharedStatus: ${sharedStatus}`);

  if ([0, 1, 2].includes(sharedStatus)) {
    if (sharedStatus == 2 && friendStore.acceptedFriendRequest == null) {
      console.debug(
        componentName,
        functionName,
        "trying to privately share without a friend; Defaulting to private",
      );
      sharedStatus = 0; // Default to private story
    } else {
      console.debug(
        componentName,
        functionName,
        `using number '${sharedStatus}' provided in URL for sharedStatus`,
      );
      return sharedStatus;
    }
  } else {
    sharedStatus = 0; // Default to private story
    console.debug(
      componentName,
      functionName,
      `defaulting to number '${sharedStatus}' for sharedStatus`,
    );
    return sharedStatus;
  }
};

const title = ref("Storyque");
const updateTitle = (newTitle) => {
  const functionName = "updateTitle";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "newTitle:", newTitle);
  title.value = newTitle;
};

let saveDraftDelay = ref(null);

const cancelDraftDelay = () => {
  const functionName = "cancelDraftDelay";
  console.info(componentName, functionName);

  if (saveDraftDelay.value) {
    console.debug(componentName, functionName, "Cancelling draft save delay");
    clearTimeout(saveDraftDelay.value);
    saveDraftDelay.value = null;
  }
};

const isDraftable = computed(() => {
  const functionName = "isDraftable[computed]";
  // Check that the story has been modified

  if (isEditMode.value) {
    console.debug(componentName, functionName, "in edit-mode; Not draftable");
    return false;
  }

  if (
    storyDraftLoadingStatus.value === StoryDraftLoadingStatus.storyDraftFound &&
    draftLoadedFromLocalStorage.value !== null
  ) {
    console.debug(
      functionName,
      "draft loaded from localStorage; Story is immediately draftable",
    );
    return true;
  }

  console.debug(
    functionName,
    "Determining story's draft-ability as it's not loaded from localStorage",
  );

  const result =
    initialStoryObj.value &&
    currentStoryObj.value &&
    currentStoryObj.value.pages.some((page, index) => {
      const lastSavedPage = initialStoryObj.value.pages[index];
      return (
        page.media.url !== lastSavedPage.media.url ||
        page.text !== lastSavedPage.text
      );
    });

  console.debug(functionName, "isDraftable computed value:", result);
  return result;
});

const isSavingDraft = ref(false);
const updateIsSavingDraft = (newValue) => {
  const functionName = "updateIsSavingDraft";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "newValue:", newValue);
  isSavingDraft.value = newValue;
};

const storyPagesInitialState = ref(null);

const storyPages = computed(() => {
  const tempStoryPages = [...storyStore.value.pages];
  console.debug(
    componentName,
    "tempStoryPages:",
    JSON.stringify(tempStoryPages),
  );
  tempStoryPages.forEach((storyPage) => {
    console.debug(componentName, "storyPage:", JSON.stringify(storyPage));
    if (storyPage?.number === undefined)
      storyPage.number = getStoryPageBackgroundNumber(
        storyPageBackgroundNumbers,
        reservedStoryPageBackgroundNumbers,
        storyPage.id,
        componentName,
      );
  });
  return tempStoryPages;
});

watch(
  () => currentStoryObj.value,
  (latestCurrentStoryObj) => {
    console.debug(
      componentName,
      "latestCurrentStoryObj:",
      JSON.stringify(latestCurrentStoryObj),
      "lastSavedStoryObj:",
      JSON.stringify(lastSavedStoryObj.value),
    );

    if (isEditMode.value) {
      console.debug(componentName, "in edit-mode; Not saving draft");
      return;
    }

    if (saveDraftDelay.value) {
      console.debug(
        componentName,
        "Clearing draft save delay when story has been updated by the user",
      );
      clearTimeout(saveDraftDelay.value);
      saveDraftDelay.value = null;
    }

    if (!latestCurrentStoryObj || !lastSavedStoryObj.value) {
      console.debug(
        componentName,
        "latestCurrentStoryObj or previousCurrentStoryObj is null; Not saving draft",
      );
      return;
    }

    // TODO: Delete story draft from localStorage if the in-progress story has been emptied
    if (
      latestCurrentStoryObj.pages.every(
        (page) => !page.media.url && !page.text,
      ) &&
      lastSavedStoryObj.value.pages.some((page) => page.media.url || page.text)
    ) {
      console.debug(
        componentName,
        "latestCurrentStoryObj has empty media.url and text on all pages while lastSavedStoryObj has not; Deleting draft from localStorage",
      );
      const success = deleteDraftStoryFromLocalStorage();
      if (success) {
        // Reset lastSavedStoryObj
        lastSavedStoryObj.value = {
          id: lastSavedStoryObj.value.id,
          sharedStatus: lastSavedStoryObj.value.sharedStatus,
          pages: [
            {
              id: lastSavedStoryObj.value.pages[0].id,
              media: {
                url: "",
                valid: null,
              },
              text: "",
            },
          ],
        };
        newStoryStore.hasBeenSavedSinceLastChange = true;
      }
      return;
    }

    if (
      latestCurrentStoryObj.pages.length === 0 &&
      lastSavedStoryObj.value.pages.length > 0
    ) {
      console.debug(
        componentName,
        "latestCurrentStoryObj.pages.length is 0; Deleting draft from localStorage",
      );
      deleteDraftStoryFromLocalStorage();
      return;
    }

    if (!newStoryStore.hasBeenSavedSinceLastChange) {
      newStoryStore.updateHasBeenSavedSinceLastChange(false);
    }

    saveDraftDelayTimestampInTesting(true);

    saveDraftDelay.value = setTimeout(() => {
      handleSaveStoryToLocalStorage(
        lastSavedStoryObj,
        currentStoryObj,
        saveDraftDelay,
        isSavingDraft,
        isDraftable,
        storyDraftLoadingStatus,
        newStoryStore.hasBeenSavedSinceLastChange,
        newStoryStore.updateHasBeenSavedSinceLastChange,
        updateIsSavingDraft,
        updateTitle,
      );
      saveDraftDelayTimestampInTesting(false);
    }, StoryConstant.draftSaveDelay);
  },
  { immediate: true, deep: true },
);

watch(
  () => storyPages.value,
  (newStoryPages) => {
    if (newStoryPages === null) return;
    storyPagesInitialState.value = [...newStoryPages];
  },
  { immediate: true, deep: true },
);

const handleStoryPageDragNDrop = computed(() => {
  return isEditMode.value
    ? editStoryStore.updateStoryPageOrderAfterDragNDrop
    : newStoryStore.updateStoryPageOrderAfterDragNDrop;
});

const isStoryPostable = computed(() => {
  if (isEditMode.value) {
    return editStoryStore.isStoryEdited && editStoryStore.validStory;
  } else {
    return newStoryStore.pages.some(
      (storyPage) => storyPage.media.valid && storyPage?.text.length >= 20,
    );
  }
});

// For selecting custom background graphics for each story page (re-use backgrounds when a story page is deleted and new one added) -->
const storyPageBackgroundNumbers = [0, 1, 2, 3, 4];
const reservedStoryPageBackgroundNumbers = ref([]);

const calculateWidthPercentage = (percentage) => {
  return (window.innerWidth * percentage) / 100;
};

// Preserve state across HMR updates
if (module.hot) {
  module.hot.accept();
  module.hot.dispose((data) => {
    data.storyPages = storyPages.value;
    data.currentStoryPageIndex = currentStoryPageIndex.value;
  });
  if (module.hot.data) {
    storyPages.value = module.hot.data.storyPages;
    currentStoryPageIndex.value = module.hot.data.currentStoryPageIndex;
  }
}

const getStoryDraftFromLocalStorage = () => {
  const functionName = "getStoryDraftFromLocalStorage";
  console.info(componentName, functionName);

  storyDraftLoadingStatus.value = StoryDraftLoadingStatus.inProgress;

  const draftStory = JSON.parse(
    localStorage.getItem(StoryConstant.localStorage.storyDraft),
  );

  console.debug(
    componentName,
    functionName,
    "draftStory:",
    JSON.stringify(draftStory),
  );

  if (draftStory === null) {
    console.debug(
      componentName,
      functionName,
      "no draft found in localStorage",
    );
    storyDraftLoadingStatus.value = StoryDraftLoadingStatus.storyDraftFound;
    return null;
  }

  if (draftStory && Object.keys(draftStory).length === 0) {
    console.debug(
      componentName,
      functionName,
      "empty draft found in localStorage; Deleting draft from localStorage",
    );
    deleteDraftStoryFromLocalStorage();
    storyDraftLoadingStatus.value = StoryDraftLoadingStatus.invalidStory;
    return null;
  }

  if (draftStory?.id === undefined || !isUUID(draftStory.id, 4)) {
    console.debug(
      componentName,
      functionName,
      "invalid draft story ID; Deleting draft from localStorage",
    );
    deleteDraftStoryFromLocalStorage();
    return null;
  }

  let invalidPages = false;
  for (const storyPage of draftStory.pages) {
    if (storyPage?.id === undefined || !isUUID(storyPage.id, 4)) {
      invalidPages = true;
      console.debug(
        componentName,
        functionName,
        "invalid storyPage ID; Deleting draft from localStorage",
      );
      break;
    }
  }

  if (invalidPages) {
    deleteDraftStoryFromLocalStorage();
    storyDraftLoadingStatus.value = StoryDraftLoadingStatus.invalidStory;
    return null;
  } else {
    storyDraftLoadingStatus.value = StoryDraftLoadingStatus.storyDraftFound;
  }

  return draftStory;
};

onBeforeMount(() => {
  console.info(componentName, "onBeforeMount");

  isEditMode.value = route.path.includes("/posts/edit/");
  console.debug(componentName, "isEditMode:", isEditMode.value);

  if (isEditMode.value) {
    console.debug(componentName, "in edit-mode");
    const storyId = route.params.postId;
    console.debug(componentName, "storyId in URL:", storyId);
    // To-be edited story's ID in URL
    if (myStoriesStore.getStoryIndexById(storyId) != -1) {
      editStoryStore.populateStoryToBeEdited(storyId);
      editStoryStore.checkStoryValidity();
    } else {
      // Invalid storyId; Not existing or user's own story
      snackBarStore.displayNotification({
        message: "Invalid story",
        color: "error",
        timeout: 2250,
      });
      router.replace("/");
    }
  } else if (
    localStorage.getItem(StoryConstant.localStorage.storyDraft) !== null
  ) {
    console.debug(componentName, "loading draft from localStorage");
    const storyDraftFromLocalStorage = getStoryDraftFromLocalStorage();
    console.debug(
      componentName,
      "draft loaded from localStorage:",
      JSON.stringify(storyDraftFromLocalStorage),
    );
    if (
      storyDraftLoadingStatus.value === StoryDraftLoadingStatus.invalidStory
    ) {
      snackBarStore.displayNotification({
        message: StoryConstant.notifications.storyDraft.load.failure,
        color: "error",
        timeout: 2500,
      });

      setTimeout(() => {
        router.replace("/");
      }, 500);
      return;
    } else if (
      storyDraftLoadingStatus.value === StoryDraftLoadingStatus.storyDraftFound
    ) {
      snackBarStore.displayNotification({
        message: StoryConstant.notifications.storyDraft.load.success,
        color: "success",
        timeout: 2000,
      });
    }
    draftLoadedFromLocalStorage.value = storyDraftFromLocalStorage;
    newStoryStore.loadStory(storyDraftFromLocalStorage);
    newStoryStore.checkStoryValidity();
    lastSavedStoryObj.value = storyDraftFromLocalStorage;
    initialStoryObj.value = storyDraftFromLocalStorage;
  } else {
    console.debug(componentName, "setting up new story");
    newStoryStore.createStory(extractSharedStatusFromUrl());
    newStoryStore.storySharedStatusListener();
    newStoryStore.checkStoryValidity();
    const storyObj = JSON.parse(JSON.stringify(newStoryStore.getStoryObject()));
    lastSavedStoryObj.value = storyObj;
    initialStoryObj.value = storyObj;
    console.debug(
      componentName,
      "new story:",
      JSON.stringify(lastSavedStoryObj.value),
    );
  }
});

onMounted(async () => {
  console.info(componentName, "onMounted");
  if (window.automatedTesting == true) {
    window.StoryCreatorView = {
      currentStoryPageIndex: currentStoryPageIndex,
      reservedStoryPageBackgroundNumbers: reservedStoryPageBackgroundNumbers,
      isEditMode: isEditMode,
      isDraftable: isDraftable,
      isSavingDraft: isSavingDraft,
      draftLoadedFromLocalStorage: draftLoadedFromLocalStorage,
      storyDraftLoadingStatus: storyDraftLoadingStatus,
      lastSavedStoryObj: lastSavedStoryObj,
    };
  }

  dimenStore.calculateIsMobile();
  await dimenStore.calculateContainerHeight(true, false);

  window.addEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});

onUnmounted(() => {
  const functionName = "onUnmounted";
  console.info(componentName, functionName);

  if (saveDraftDelay.value) {
    console.debug(componentName, functionName, "Clearing draft save delay");
    clearTimeout(saveDraftDelay.value);
    saveDraftDelay.value = null;
  }
  if (isEditMode.value) {
    editStoryStore.reset();
  } else {
    newStoryStore.deleteStory();
  }

  window.removeEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});
</script>

<style scoped>
p br::after {
  content: "\A"; /* Using a generated content with '\A' for a newline */
  white-space: pre; /* Preserve whitespace to ensure the newline is rendered */
}

.create-story-pages-list {
  width: 500px;
}

.v-card {
  text-align: left;
}

.v-card-item__content {
  padding-top: 10px;
}

.v-input {
  margin-bottom: 10px;
}

.panel-header {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.panel-content {
  display: none;
  padding: 10px;
}

.horizontal-scroll-item {
  align-self: center;
  justify-content: center;
  height: 100%;
  margin-right: 25px;
  border-radius: 10px;
  overflow: hidden;
}

.img-with-margin {
  margin: 10px; /* Adjust margin as needed */
  width: 500px; /* Subtract the horizontal margins from the width */
  height: 500px; /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.bottom-container {
  flex: 0.5;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%; /* Subtract the horizontal margins from the width */
  height: calc(100% - 20px); /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.text-container {
  flex: 1;
  margin: 10px; /* Adjust margin as needed */
  width: calc(100% - 20px); /* Subtract the horizontal margins from the width */
  height: calc(100% - 20px); /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
</style>

<style
  lang="css"
  scoped
  src="../components/storyCreator/storyCreatorViewStoryPageBackgrounds.css"
></style>
