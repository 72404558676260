module.exports = Object.freeze({
  storyCreatorView: {
    backButton: "storyCreatorView-button-back",
    appBar: {
      title: "storyCreatorView-appBar-title",
      saveDraftButton: "storyCreatorView-button-save-draft",
      resetStoryButton: "storyCreatorView-button-reset-story",
    },
    storyMedia: {
      placeholder: "story-media-main-img-placeholder#", // Add story page ID at the end
      realMedia: "story-media-main-img#", // Add story page ID at the end
    },
    storyText: "story-text-input-field#", // Add story page ID at the end
    bottomPages: {
      storyPageButton: "story-page-button-bottom#", // Add story page ID at the end
      addPageButton: "storyCreatorView-button-add-story-page",
      deletePageButton: "story-page-delete-button#", // Add story page ID at the end
    },
    pendingStoryChangesDialog: {
      dialog: "pending-story-changes-dialog",
      backButton: "pending-story-changes-dialog-button-back",
      saveDraftButton: "pending-story-changes-dialog-button-save",
      discardDraftButton: "pending-story-changes-dialog-button-discard",
    },
  },
  storyDraftEditorView: {
    main: "storyDraftEditorView-main",
    appBar: {
      backButton: "storyDraftEditorView-button-back",
      deleteDraftButton: "storyDraftEditorView-button-delete-draft",
      saveDraftButton: "storyDraftEditorView-button-save-draft",
      postDraftButton: "storyDraftEditorView-button-post-draft",
    },
    storyMedia: {
      placeholder: "storyDraftEditorView-story-media-main-img-placeholder#", // Add story page ID at the end
      realMedia: "storyDraftEditorView-story-media-main-img#", // Add story page ID at the end
    },
    storyText: "storyDraftEditorView-story-text-input-field#", // Add story page ID at the end
    draftStoryChangesDialog: {
      dialog: "draft-story-changes-dialog",
      backButton: "draft-story-changes-dialog-button-back",
      deleteDraftButton: "draft-story-changes-dialog-button-delete",
      saveDraftButton: "draft-story-changes-dialog-button-save",
      postDraftButton: "draft-story-changes-dialog-button-post",
    },
    bottomSheet: {
      main: "storyDraftEditorView-bottom-sheet",
      action: "storyDraftEditorView-bottom-sheet-action#",
    },
    deleteDraftDialog: {
      dialog: "delete-draft-dialog",
      confirmButton: "delete-draft-dialog-button-confirm",
      cancelButton: "delete-draft-dialog-button-cancel",
    },
    pendingDraftStoryChangesDialog: {
      dialog: "pending-draft-story-changes-dialog",
      backButton: "pending-draft-story-changes-dialog-button-back",
      deleteDraftButton: "pending-draft-story-changes-dialog-button-delete",
      saveDraftButton: "pending-draft-story-changes-dialog-button-save",
      postDraftButton: "pending-draft-story-changes-dialog-button-post",
      discardDraftButton: "pending-draft-story-changes-dialog-button-discard",
    },
  },
  dashboardTabs: {
    draftsTab: "dashboard-tab-drafts",
    publishedTab: "dashboard-tab-published",
  },
  draftStoriesListView: {
    draftStoryItem: {
      main: "draft-story-item#", // Add draft story ID at the end
      media: "draft-story-item-media#", // Add draft story ID at the end
      editButton: "draft-story-item-button-edit#", // Add draft story ID at the end
      deleteButton: "draft-story-item-button-delete#", // Add draft story ID at the end
    },
  },
  common: {
    deleteDraftDialog: {
      dialog: "delete-draft-dialog",
      confirmButton: "delete-draft-dialog-button-confirm",
      cancelButton: "delete-draft-dialog-button-cancel",
    },
  },
  storyViewer: {
    appBar: {
      backButton: "story-viewer-appBar-button-back",
    },
    storyMedia: "story-viewer-story-media#", // Add story page ID at the end
    storyText: "story-viewer-story-text#", // Add story page ID at the end
  },
});
