import { firestore } from "@/firebase";
import { useAuthStore } from "@/stores/authStore";
import { useDraftStoriesStore } from "@/stores/draftStoriesStore";
import { collection, getDocs, limit, query, where } from "firebase/firestore";

export default async function fetchStoryData(postId, isDraftStory = false) {
  const functionName = "fetchStoryData";
  console.info(functionName);
  console.debug(functionName, "postId:", postId, "isDraftStory:", isDraftStory);

  const authStore = useAuthStore();
  console.debug(functionName, "userId:", authStore.userId);

  if (isDraftStory) {
    console.debug(functionName, "fetching draft story");

    const draftStoriesStore = useDraftStoriesStore();
    let draftStory = draftStoriesStore.stories.find(
      (story) => story.id === postId,
    );

    if (draftStory) {
      console.debug(functionName, "postId matched with a draft story");
      return draftStory;
    } else {
      console.debug(functionName, "No draft story found with postId:", postId);
      return null;
    }
  }

  // Else it's probably an already published story
  try {
    const q = query(
      collection(firestore, "stories"),
      where("id", "==", postId),
      where("sharedTo", "array-contains-any", ["", authStore.userId]),
      limit(1),
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.size == 0) {
      console.error(functionName, "No story found with postId:", postId);
      return null;
    }

    const doc = querySnapshot.docs[0];
    let docData = doc.data();
    console.debug(functionName, "docData:", JSON.stringify(docData));
    return docData;
  } catch (error) {
    // Handle any potential errors
    console.error(functionName, "Error fetching document:", error);
  }
}

if (window.automatedTesting && typeof window !== "undefined") {
  window.originalFetchStoryData = fetchStoryData;
}
