import SharedStatus from "@/constants/SharedStatus";
import createFirestoreStoryDoc from "@/utils/story/createFirestoreStoryDoc";
import deleteNewStoryRequest from "@/utils/story/deleteNewStoryRequest";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "./authStore";
import { useFriendStore } from "./friendStore";
import { useNotificationStore } from "./notificationStore";
import { useSnackBarStore } from "./snackBarStore";
import { useStoriesSharedWithYou } from "./storiesSharedWithYouStore";

const storeName = "newStoryStore";

export const useNewStoryStore = defineStore({
  id: storeName,
  state: () => ({
    id: ref(null),
    sharedStatus: ref(SharedStatus.private),
    pages: ref([]),
    posting: ref(false),
    postSuccess: ref(null),
    validStory: ref(false),
    snackbar: ref(false),
    authStore: useAuthStore(),
    router: useRouter(),
    snackBarStore: useSnackBarStore(),
    friendStore: useFriendStore(),
    storiesSharedWithYouStore: useStoriesSharedWithYou(),
    notificationStore: useNotificationStore(),
    hasBeenSavedSinceLastChange: ref(false),
  }),
  getters: {
    pendingChanges(state) {
      if (this.hasBeenSavedSinceLastChange) {
        console.debug(
          storeName,
          "pendingChanges",
          "pending changes detected but have already been saved to localStorage",
        );
        return false;
      } else {
        return (
          state.pages?.length > 0 &&
          state.pages.some(
            (storyPage) =>
              storyPage.media.url?.length > 0 || storyPage.text?.length > 0,
          )
        );
      }
    },
  },
  actions: {
    updateStoryPageText(pageId, newText) {
      const functionName = "updateStoryPageText";
      console.info(storeName, functionName);

      console.debug(
        storeName,
        functionName,
        "pageId:",
        pageId,
        "newText:",
        newText,
      );

      const index = this.getPageIndexById(pageId);
      if (index !== -1) {
        this.pages[index] = { ...this.pages[index], text: newText };
      } else {
        console.error(storeName, functionName, "Page ID not found");
      }
    },
    storySharedStatusListener() {
      const functionName = "storySharedStatusListener";
      watch(
        () => this.sharedStatus,
        async (newSharedStatus, oldSharedStatus) => {
          console.debug(
            storeName,
            functionName,
            "newSharedStatus:",
            newSharedStatus,
            "oldSharedStatus:",
            oldSharedStatus,
          );

          console.debug(
            storeName,
            functionName,
            `Story will be posted as ${
              newSharedStatus == 0
                ? "private"
                : newSharedStatus == 1
                ? "public"
                : `shared with ${this.friendStore.acceptedFriendRequest.receiverName}`
            } `,
          );
        },
        { immediate: false },
      );
    },
    getPageIndexById(pageId) {
      return this.pages.findIndex((storyPage) => storyPage.id === pageId);
    },
    createStory(sharedStatus = 0) {
      const functionName = "createStory";
      console.info(storeName, functionName);
      this.id = uuidv4();
      this.sharedStatus = sharedStatus;
      const newStoryPage = {
        id: uuidv4(),
        media: { url: "", valid: null },
        text: "",
      };
      this.pages.push(newStoryPage);
      console.debug(storeName, functionName, "story's ID: ", this.id);
    },
    addStoryPage() {
      const functionName = "addStoryPage";
      console.info(storeName, functionName);
      const newStoryPage = {
        id: uuidv4(),
        media: { url: "", valid: false },
        text: "",
      };
      this.pages.push(newStoryPage);
      console.debug(storeName, functionName, "story: ", this.pages);
    },
    resetStoryTitle() {
      this.title = "";
    },
    resetStoryPageMedia(pageId) {
      const functionName = "resetStoryPageMedia";
      console.info(storeName, functionName);
      const index = this.getPageIndexById(pageId);
      this.pages[index].media = { url: "", valid: false };
    },
    resetStoryPageText(pageId) {
      const index = this.getPageIndexById(pageId);
      this.pages[index].text = "";
    },
    resetStory() {
      const functionName = "resetStory";
      console.info(storeName, functionName);
      this.id = uuidv4();
      const originalFirstStoryPageId = this.pages[0].id;
      this.pages = [
        {
          id: originalFirstStoryPageId,
          media: { url: "", valid: null },
        },
      ];
      this.sharedStatus = SharedStatus.private;
    },
    deleteStoryPage(pageId) {
      const functionName = "deleteStoryPage";
      console.info(storeName, functionName);
      const index = this.getPageIndexById(pageId);
      if (index !== -1) {
        console.debug(
          storeName,
          functionName,
          "deleting story page at index:",
          index,
        );
        this.pages.splice(index, 1); // Removes 1 element at the found index
      } else {
        console.debug(storeName, functionName, "story page's ID not found");
      }
    },
    async validateGifUrl(url) {
      const functionName = "validateGifUrl";
      console.info(storeName, functionName);

      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          console.debug(storeName, functionName, "valid URL:", url);
          resolve(true);
        };
        img.onerror = () => {
          console.error(storeName, functionName, "invalid URL:", url);
          resolve(false);
        };
        img.src = url;
      });
    },
    async checkGifUrl(storyPageId) {
      const isValid = await this.validateGifUrl(
        this.pages[this.getPageIndexById(storyPageId)].media.url,
      );
      this.setUrlValidity(storyPageId, isValid);
    },
    setUrlValidity(pageId, validStatus) {
      const functionName = "setUrlValidity";
      console.info(storeName, functionName);
      console.debug(
        storeName,
        functionName,
        "pageId:",
        pageId,
        "validStatus:",
        validStatus,
      );
      const index = this.getPageIndexById(pageId);
      console.debug(storeName, functionName, "index:", index);
      if (index == -1) {
        console.error(
          storeName,
          functionName,
          "invalid index for story page:",
          index,
        );
        return;
      }
      this.pages[index].media.valid = validStatus;

      console.debug(storeName, functionName, "validStatus:", validStatus);
      if (!validStatus) {
        this.pages[index].media.url = "";
        this.snackBarStore.displayNotification({
          message: "Unable to load media source",
          color: "error",
          timeout: 2500,
        });
      }
    },
    deleteStory() {
      const functionName = "deleteStory";
      console.info(storeName, functionName);
      this.id = null;
      this.pages = [];
    },
    getSharedToArray(sharedStatus = null) {
      const functionName = "getSharedToArray";
      console.info(storeName, functionName);

      const status = this.sharedStatus ?? sharedStatus;

      if (typeof status !== "number") {
        console.error(
          storeName,
          functionName,
          "sharedStatus is not a number",
          status,
        );
      }

      switch (status) {
        case 0:
          return [this.authStore.userId];
        case 1:
          return [""];
        case 2:
          return [this.authStore.userId, this.friendStore.getFriendUserId()];
      }
    },
    async postStory() {
      const functionName = "postStory";
      /**
       * Creates a new story with:
       * - id: story's randomly generated ID
       * - sharedStatus:  0 = private (only accessible by creator),
       *                  1 = public (accessible by anyone with a link),
       *                  2 = privately shared (only accessible by creator and their current friend)
       * - sharedTo: ID of the creator's friend in case sharedStatus is set to 2 ( = privately shared)
       * - createdAt: Timestamp of story's creation time
       * - creatorId: Creator's ID (user ID)
       * - title (not actually never used)
       * - pages
       * -- id: page's ID
       * -- mediaUrl: URL to media to be played
       * -- text
       */
      this.posting = true;

      const newStoryToBeUploaded = {
        id: this.id,
        sharedTo: this.getSharedToArray(),
        createdAt: new Date().toUTCString(),
        creatorId: this.authStore.userId,
        creatorName: this.authStore.username,
        pages: [],
      };

      this.pages.forEach((page) => {
        const _page = {
          id: page.id,
          mediaUrl: page.media.url,
          text: page.text,
        };

        newStoryToBeUploaded.pages.push(_page);
      });

      console.debug(
        storeName,
        functionName,
        "new story:",
        newStoryToBeUploaded,
      );

      let docCreated;
      try {
        docCreated = await createFirestoreStoryDoc(newStoryToBeUploaded);
      } catch (error) {
        console.error(storeName, functionName, error);
      }

      // X06H8yxx: Delete newStoryRequest-document if any exist, when creating a new story with sharedStatus of 2 (privately shared) -->
      if (
        docCreated &&
        this.sharedStatus == SharedStatus.privatelyShared &&
        !this.storiesSharedWithYouStore.allowNewStoryRequest &&
        this.storiesSharedWithYouStore.newStoryRequestDocRef != null
      ) {
        // Delete newStoryRequest-document
        console.debug(
          storeName,
          functionName,
          "deleting newStoryRequest-document",
        );

        await deleteNewStoryRequest(
          this.storiesSharedWithYouStore.newStoryRequestDocRef,
        );

        // Remove possible pending New Story Request -notification
        this.notificationStore.removeNotification("requestStory");
      } else {
        console.debug(
          storeName,
          functionName,
          "not deleting newStoryRequest-document",
        );
      }
      // <--

      this.postSuccess = docCreated;
      this.snackbar = true;
      setTimeout(() => {
        this.router.replace("/home");
        this.posting = false;
      }, 450);
    },
    checkStoryValidity() {
      const functionName = "checkStoryValidity";
      watch(
        () => structuredClone(this.pages),
        (newPages) => {
          console.debug(
            storeName,
            functionName,
            "new story pages:",
            JSON.stringify(newPages),
          );

          if (
            newPages.some((storyPage) => !storyPage.media?.valid) ||
            newPages.some((storyPage) => storyPage.text?.length < 20)
          ) {
            console.debug(
              storeName,
              functionName,
              "invalid story pages detected",
              JSON.stringify(newPages),
            );
            this.validStory = false;
          } else {
            console.debug(
              storeName,
              functionName,
              "valid story pages detected",
              JSON.stringify(newPages),
            );
            this.validStory = true;
          }
        },
        { deep: true },
      );
    },

    updateStoryPageOrderAfterDragNDrop(event) {
      const functionName = "updateStoryPageOrderAfterDragNDrop";
      console.info(storeName, functionName);
      console.info(storeName, functionName, "event: ", event);
      /**
       * Used with updated StoryCreatorView with drag-n-droppable story pages
       */

      // const pageIndex = this.getPageIndexById(pageId)

      // if (pageIndex > -1) {
      //   const pageObj = this.pages[pageIndex]
      //   // Remove the object from its current position
      //   arr.splice(pageIndex, 1);

      //   // Insert the object at the last index
      //   arr.push(pageObj);
      // }

      if (event.oldIndex === -1 || event.newIndex === -1) {
        console.error(
          storeName,
          functionName,
          "Invalid page index after drag-n-drop",
        );
        return;
      }

      console.debug(
        storeName,
        functionName,
        "oldIndex:",
        event.oldIndex,
        "newIndex:",
        event.newIndex,
      );

      const pageObj = this.pages[event.oldIndex];
      // Remove the object from its current position
      this.pages.splice(event.oldIndex, 1);

      this.pages.splice(event.newIndex, 0, pageObj);
    },
    pendingNewStory() {
      const functionName = "pendingNewStory";
      console.info(storeName, functionName);

      const mediaAdded = this.pages.some(
        (storyPage) => storyPage.media.url?.length > 0,
      );

      if (mediaAdded) {
        console.debug(
          storeName,
          functionName,
          "media added to pending new story",
        );
        return true;
      }

      const textAdded = this.pages.some(
        (storyPage) => storyPage.text?.length > 0,
      );

      if (textAdded) {
        console.debug(
          storeName,
          functionName,
          "text added to pending new story",
        );
        return true;
      }

      console.debug(storeName, functionName, "empty story");

      return false;
    },
    getStoryObject() {
      const functionName = "getStoryObject";
      console.info(storeName, functionName);

      return {
        id: this.id,
        sharedStatus: this.sharedStatus,
        pages: this.pages,
      };
    },
    async loadStory(story) {
      const functionName = "loadStory";
      console.info(storeName, functionName);

      console.debug(storeName, functionName, "story:", JSON.stringify(story));

      let storyPages = await Promise.all(
        story.pages.map(async (page) => {
          if (page.media.url?.length > 0) {
            page.media.valid = await this.validateGifUrl(page.media.url);
          }
          return { ...page };
        }),
      );

      const storyToBeLoaded = {
        creatorId: story.creatorId,
        creatorName: story.creatorName,
        id: story.id,
        sharedStatus: story.sharedStatus,
        sharedTo: this.getSharedToArray(story.sharedStatus),
        pages: [...storyPages],
      };

      console.debug(
        storeName,
        functionName,
        "story to be loaded:",
        JSON.stringify(storyToBeLoaded),
      );

      this.$patch((state) => {
        state.creatorId = storyToBeLoaded.creatorId;
        state.creatorName = storyToBeLoaded.creatorName;
        state.id = storyToBeLoaded.id;
        state.sharedStatus = storyToBeLoaded.sharedStatus;
        state.sharedTo = this.getSharedToArray(storyToBeLoaded.sharedStatus);
        state.pages = [...storyToBeLoaded.pages];
      });
    },
    updateSharedStatus(newSharedStatus) {
      const functionName = "updateSharedStatus";
      console.info(storeName, functionName);
      console.debug(
        storeName,
        functionName,
        "newSharedStatus:",
        newSharedStatus,
      );
      this.sharedStatus = newSharedStatus;
    },
    updateHasBeenSavedSinceLastChange(newValue) {
      this.hasBeenSavedSinceLastChange = newValue;
    },
  },
});
